import {
    collection,
    collectionGroup,
    deleteDoc,
    doc,
    getDocs,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where
} from "firebase/firestore";

import * as actionTypes from './actionTypes';
import { firestore } from "../../firebase";

async function getParts(email, isAdmin) {
    let parts = [];
    const partsRef = query(collection(firestore, "parts"), orderBy("name", "asc"));
    const querySnapshot = await getDocs(partsRef);

    const filters = [];

    if (!isAdmin) {
        const filterRef = query(collectionGroup(firestore, "filters"), where("usersToFilter", "array-contains", email));
        const querySnapshot = await getDocs(filterRef);

        querySnapshot.forEach(filter => {
            filters.push(filter.ref.parent.parent.id);
        });
    }

    querySnapshot.forEach((part) => {
        if (isAdmin || !filters.includes(part.id)) {
            parts.push({ ...part.data(), id: part.id });
        }
    });
    return parts;
}

export const onFetchParts = (email, isAdmin) => {
    return dispatch => {
        dispatch(partsStart());
        getParts(email, isAdmin)
            .then((parts) => {
                dispatch(partsSuccess());
                dispatch(setParts(parts));
            })
            .catch(error => {
                partsFail(error)
            });
    }
};

export const partsStart = () => {
    return {
        type: actionTypes.PARTS_START
    };
};

export const partsSuccess = () => {
    return {
        type: actionTypes.PARTS_SUCCESS
    };
};

export const partsFail = (error) => {
    return {
        type: actionTypes.PARTS_FAIL,
        error: error
    };
};

export const addPart = (part) => {
    return {
        type: actionTypes.ADD_PART,
        part: part
    };
};

export const onAddPart = (part, id) => {
    const updatedPart = { ...part, id };
    return dispatch => {
        dispatch(partsStart());
        setDoc(doc(firestore, "parts", id), part)
            .then(() => {
                dispatch(partsSuccess());
                dispatch(addPart(updatedPart));
            })
            .catch(error => {
                dispatch(partsFail(error));
            });
    }
};

export const editPart = (part, id) => {
    return {
        type: actionTypes.EDIT_PART,
        part: part,
        id: id
    };
};

export const onEditPart = (part, id) => {
    let cleanedPart = { ...part };
    delete cleanedPart.id;
    delete cleanedPart.category;

    return dispatch => {
        dispatch(partsStart());
        updateDoc(doc(firestore, "parts", id), cleanedPart)
            .then(() => {
                dispatch(partsSuccess());
                dispatch(editPart(part, id));
            })
            .catch(error => {
                dispatch(partsFail(error));
            });
    }
};


export const removePart = (id) => {
    return {
        type: actionTypes.REMOVE_PART,
        id: id
    };
};

export const onRemovePart = (id) => {
    return dispatch => {
        dispatch(partsStart());
        deleteDoc(doc(firestore, "parts", id))
            .then(() => {
                dispatch(partsSuccess());
                dispatch(removePart(id));
            })
            .catch(error => {
                dispatch(partsFail(error));
            });
    }
};

export const setParts = (parts) => {
    return {
        type: actionTypes.SET_PARTS,
        parts: parts
    };
};
