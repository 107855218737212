import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';

import {Button, CardMedia, Slide, TextField, Typography} from '@mui/material';

import alt_image from '../../../../../../assets/images/alt_image.jpg';
import * as actions from '../../../../../../store/actions';
import * as classes from './ItemCard.module.css';

const ItemCard = ({item, onAddItem, enqueueSnackbar}) => {
  const [amount, setAmount] = useState(item.amount ? item.amount : '1');
  const pictureUrl = item.pictures?.length > 0 ? item.pictures[0].pictureUrl : alt_image;

  const handleAddToCart = () => {
    const amountInt = parseInt(amount);
    onAddItem(item, amountInt);
    const message = `Item${amountInt > 1 ? 's' : ''} added to your cart`;
    enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: 3000,
      TransitionComponent: Slide,
      anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
    });
  };

  return (
      <div key={item.id}>
        <div className={classes.Wrapper}>
          <div className={classes.LeftContent}>
            <CardMedia
                loading={'lazy'}
                style={{maxHeight: 50, maxWidth: 50}}
                component={'img'}
                alt={item.name}
                image={pictureUrl}
            />
            <Typography variant='h6'>
              {item.name}
            </Typography>
          </div>
          <div className={classes.RightContent}>
            <TextField
                type={'number'}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                variant={'outlined'}
                label={'Quantity'}
                onInput={(e) => {
                  e.target.value = Math.max(0, e.target.value.slice(0, 5));
                }}
                InputProps={{inputProps: {min: 1, max: 1000000}}}
            />
            <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleAddToCart}
            >
              Add to Cart
            </Button>
          </div>
        </div>
      </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onAddItem: (item, amount) => dispatch(actions.onAddItem(item, amount)),
  };
};

export default connect(null, mapDispatchToProps)(withSnackbar(ItemCard));
