import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import {
    Button,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField
} from "@mui/material";
import { Add, Delete, Edit, GetApp } from '@mui/icons-material';

import useStyles from "../../../components/UI/Styles/formStyle";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "black",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
            backgroundColor: "lightgray",
        },
        "&:hover": {
            color: 'black',
        },
    },
}))(TableRow);

const StyledSortLabel = withStyles(() => ({
    root: {
        color: 'white',
        '&:hover': {
            color: 'white',
        },
        '&$active': {
            color: 'white',
        },
    },
    active: {
        color: 'white',
    },
    icon: {
        color: 'inherit !important'
    },
}))(TableSortLabel);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {props.headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.noSort ?
                            headCell.label :
                            <StyledSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id
                                    ?
                                    (<span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>)
                                    :
                                    null}
                            </StyledSortLabel>}
                    </StyledTableCell>
                ))}
                {props.edit && <StyledTableCell align="left">Edit</StyledTableCell>}
                {props.accept && <StyledTableCell align="left">Accept</StyledTableCell>}
                {props.delete && <StyledTableCell align="left">Delete</StyledTableCell>}
                {props.actions && <StyledTableCell align="left">Actions</StyledTableCell>}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('first');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState(props.data);

    const { data } = props;
    useEffect(() => {
        setRows(data);
        setRowsPerPage(data.length >= 25 && !props.noPagination ? 25 : data.length > 0 ? data.length : 5);
    }, [data, props.noPagination]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.valueAsNumber);
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 7 }}>
                {props.add && <Button startIcon={<Add />} color={"primary"}
                                      disabled={props.addDisabled} variant="contained"
                                      onClick={() => props.add()}>Add</Button>}
                {props.download &&
                    <Button startIcon={<GetApp />} color={"primary"} disabled={props.downloadDisabled}
                            variant="contained"
                            onClick={() => props.download()}>Download</Button>}
                {props.handleApprovedSwitch &&
                    <span>{props.approvedLabel} <Switch checked={props.approvedSwitch}
                                                        onChange={props.handleApprovedSwitch} /></span>
                }
            </div>
            <TableContainer component={Paper}
                            style={{ ...props.straightCorners, maxHeight: props.maxHeight ? props.maxHeight : 550 }}>
                <Table
                    stickyHeader
                    size={'small'}
                >
                    {props.caption && <caption>{props.caption}</caption>}
                    <EnhancedTableHead
                        {...props}
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {(rowsPerPage > 0 ? stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : stableSort(rows, getComparator(order, orderBy)))
                            .map((row, index) => {
                                return (
                                    <StyledTableRow
                                        style={row.partialOrder ? { backgroundColor: "lightyellow" } : null}
                                        hover
                                        key={index}
                                    >
                                        {/*go through all the normal cells in the head cells array, special rendering for components*/}
                                        {props.headCells.map((cell, innerIndex) => {
                                            if (cell.component) {
                                                if (cell.click) {
                                                    return (
                                                        <StyledTableCell key={innerIndex} align="left">
                                                            <Button onClick={() => {
                                                                cell.click(row, index)
                                                            }} variant="contained" color={cell.buttonColor}
                                                                    startIcon={cell.icon}>{cell.label}</Button>
                                                        </StyledTableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <StyledTableCell key={innerIndex} align="left">
                                                            <TextField
                                                                value={row[cell.value]}
                                                                onChange={event => cell.onChange(row, event.target.valueAsNumber, index)}
                                                                variant="outlined"
                                                                fullWidth
                                                                type={"number"}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        ...cell.inputProps,
                                                                        max: row[cell.max]
                                                                    }
                                                                }}
                                                            />
                                                        </StyledTableCell>
                                                    );
                                                }
                                            } else {
                                                let data = row;

                                                if (cell.id) {
                                                    data = row[cell.id];
                                                }
                                                return (
                                                    <StyledTableCell component="th" key={innerIndex} scope="row"
                                                                     align="left">{data}</StyledTableCell>
                                                );
                                            }
                                        })}
                                        {/*special columns for specific tables*/}
                                        {props.accept &&
                                            <StyledTableCell align="left">
                                                <Button onClick={() => {
                                                    props.accept(row)
                                                }} variant="contained" color={"primary"}>Accept</Button>
                                            </StyledTableCell>
                                        }
                                        {props.edit &&
                                            <StyledTableCell align="left">
                                                <Button
                                                    variant="contained"
                                                    color={"success"}
                                                    startIcon={<Edit />}
                                                    onClick={() => {
                                                        props.edit(row, index)
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            </StyledTableCell>
                                        }
                                        {props.delete &&
                                            <StyledTableCell align="left">
                                                <Button startIcon={<Delete />} onClick={() => {
                                                    props.delete(row)
                                                }} variant="contained" color={"secondary"}>Delete</Button>
                                            </StyledTableCell>
                                        }
                                        {props.actions &&
                                            <StyledTableCell align="left">
                                            <span style={{ display: 'flex' }}>
                                                <Button startIcon={<Edit />} onClick={() => {
                                                    props.actionEdit(row)
                                                }} variant="contained" color={"success"}
                                                        style={{ marginRight: 10 }}>Edit</Button>
                                                <Button startIcon={<Delete />} onClick={() => {
                                                    props.actionDelete(row)
                                                }} variant="contained" color={"secondary"}>Delete</Button>
                                            </span>
                                            </StyledTableCell>
                                        }
                                    </StyledTableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 33 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!props.noPagination && <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: rows.length }]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Fragment>
    );
}

export default EnhancedTable;
