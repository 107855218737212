import { collection, getDocs, orderBy, query } from "firebase/firestore";

import * as actionTypes from './actionTypes';
import { firestore } from "../../firebase";

async function getCategories() {
    let categories = [];
    const categoryRef = query(collection(firestore, "categories"), orderBy("name", "asc"));
    const querySnapshot = await getDocs(categoryRef);
    querySnapshot.forEach((doc) => {
        categories.push({ ...doc.data(), id: doc.id });
    });
    return categories;
}

export const onFetchCategories = () => {
    return dispatch => {
        dispatch(categoriesStart());
        getCategories()
            .then((categories) => {
                dispatch(categoriesSuccess());
                dispatch(setCategories(categories));
            })
            .catch(error => {
                categoriesFail(error)
            });
    }
};

export const categoriesStart = () => {
    return {
        type: actionTypes.CATEGORIES_START
    };
};

export const categoriesSuccess = () => {
    return {
        type: actionTypes.CATEGORIES_SUCCESS
    };
};

export const categoriesFail = (error) => {
    return {
        type: actionTypes.CATEGORIES_FAIL,
        error: error
    };
};

export const setCategories = (categories) => {
    return {
        type: actionTypes.SET_CATEGORIES,
        categories: categories
    };
};
