import React from "react";
import { NavLink } from "react-router-dom";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

import classes from "./NavigationItem.module.css";

const navigationItem = ({ link, icon, text }) => (
    <ListItem className={classes.NavigationItem}>
        <NavLink
            activeclassname={classes.active}
            to={link}
            exact={"true"}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
        </NavLink>
    </ListItem>
);

export default navigationItem;
