import React from 'react';

import classes from './EcIconWords.module.css';
import ec_logo_words from '../../images/ec-logo-words-2023.png';

const EcIconWords = ({className}) => (
    <img
        className={`${classes.Frame} ${className}`}
        src={ec_logo_words}
        alt={'ec logo'}
    />
);

export default EcIconWords;
