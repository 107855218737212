import React from 'react';

import {Container, Grid, Grow} from '@mui/material';

import ItemCard from '../CutSheets/Cards/ItemCard';
import EC_Recommended_Maintenance from '../../../../assets/files/Manager Resources/EC_Recommended_Maintenance.pdf';

const ManagerResources = () => {
  const sheets = [
    {
      name: 'Recommended Preventative Maintenance',
      file: 'EC_Recommended_Maintenance',
    },
  ];

  const pdfs = [
    EC_Recommended_Maintenance,
  ];

  return (
      <Container>
        <Grid
            container
            spacing={2}
        >
          {sheets.map((item, index) => {
            return (
                <Grow
                    in={true}
                    key={item.name}
                    style={{transformOrigin: '0 0 0'}} {...{timeout: 100}}>
                  <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                  >
                    <ItemCard
                        item={item}
                        pdf={pdfs[index]}
                    />
                  </Grid>
                </Grow>
            );
          })}
        </Grid>
      </Container>
  );
};

export default ManagerResources;
