import React from 'react';

import {Backdrop, Container, Divider, Fade, Modal, Typography} from '@mui/material';
import {HighlightOff} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';

import * as classes from '../../../containers/Pages/Client/Shopping/Checkout/Checkout.module.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex !important',
    justifyContent: 'center !important',
    margin: theme.spacing(2) + ' !important',
    textAlign: 'center !important',
    alignItems: 'center !important',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper + ' !important',
    minWidth: 200,
    boxShadow: theme.shadows[5] + ' !important',
    padding: '12px !important',
    borderRadius: '15px !important',
    position: 'relative !important',
  },
  modalFormWrapper: {
    marginTop: 12,
    maxHeight: '80vh !important',
    '& .MuiGrid2-container': {
      overflow: 'auto !important',
    },
  },
  modalExitButton: {
    cursor: 'pointer !important',
    position: 'absolute !important',
    left: 'calc(100% - 35px) !important',
    top: '0 !important',
    zIndex: '1 !important',
    backgroundColor: 'white !important',
    borderRadius: '25px !important',
    padding: 0,
    pointerEvents: 'auto !important',
    '&:disabled': {
      cursor: 'not-allowed !important',
    },
  },
}));

const TransitionModal = ({title, form, open, handleClose, loading, alignTop, maxWidth = 'sm'}) => {
  const styles = useStyles();
  const modalPosition = alignTop ? {alignItems: 'start'} : {alignItems: 'center'};

  return (
      <Modal
          className={styles.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={modalPosition}
      >
        <Fade in={open}>
          <Container
              maxWidth={maxWidth}
              className={styles.modalPaper}
          >
            <HighlightOff
                onClick={handleClose}
                disabled={loading}
                color={'error'}
                fontSize={'large'}
                className={styles.modalExitButton}
            />
            <Typography
                variant={'h5'}
                className={classes.Title}
            >
              {/*<Bubble left={2} />*/}
              {/*<Bubble left={90} top={33} />*/}
              {title}
            </Typography>
            {title && <Divider fullwidth={'true'} />}
            <div className={styles.modalFormWrapper}>
              {form}
            </div>
          </Container>
        </Fade>
      </Modal>
  );
};

export default TransitionModal;
