import React from "react";

import { Box, LinearProgress, Typography } from "@mui/material";

const CustomLinearProgress = (props) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2">{`${Math.round(
                props.value,
            )}%`}</Typography>
        </Box>
    </Box>
);

export default CustomLinearProgress;
