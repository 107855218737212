import React, {useContext, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {connect} from 'react-redux';
import {SnackbarProvider} from 'notistack';

import CartInventory from './containers/Pages/Install/CartInventory/CartInventory';
import Filters from './containers/Pages/Admin/Filters/Filters';
import ManagerResources from './containers/Pages/Client/ManagerResources/ManagerResources';
import SheetsView from './containers/Pages/Client/CutSheets/SheetsView';
import CutSheets from './containers/Pages/Client/CutSheets/CutSheets';
import Orders from './containers/Pages/Admin/Orders/Orders';
import OrderHistory from './containers/Pages/Client/OrderHistory/OrderHistory';
import Page404 from './containers/Auth/404/404';
import Register from './containers/Auth/Register/Register';
import Login from './containers/Auth/Login/Login';
import Logout from './containers/Auth/Logout/Logout';
import Forgot from './containers/Auth/Forgot/Forgot';
import Layout from './containers/hoc/Layout/Layout';
import Home from './containers/Pages/Home/Home';
import Users from './containers/Pages/Admin/Users/Users';
import Parts from './containers/Pages/Admin/Parts/Parts';
import Shopping from './containers/Pages/Client/Shopping/Shopping';
import Category from './containers/Pages/Admin/Categories/Category';
import Kits from './containers/Pages/Admin/Kits/Kits';
// import QrCode from './containers/Pages/Admin/QrCode/QrCode';
import Checkout from './containers/Pages/Client/Shopping/Checkout/Checkout';
import {AuthContext} from './containers/Auth/Auth';
import * as actions from './store/actions/index';

const App = (props) => {
  const {currentUser, isAdmin, isInstall} = useContext(AuthContext);
  const {getCurrentUser, onFetchParts, onFetchCategories, onFetchUsers} = props;

  useEffect(() => {
    if (currentUser) {
      if (isAdmin) {
        onFetchUsers();
      } else {
        getCurrentUser();
      }
    }
  }, [currentUser, isAdmin, getCurrentUser, onFetchUsers]);

  useEffect(() => {
    if (currentUser) {
      onFetchParts(currentUser.email, isAdmin);
      onFetchCategories();
    }
  }, [currentUser, isAdmin, onFetchParts, onFetchCategories]);

  const routes = [
    <Route
        key={0}
        path='*'
        element={<Page404 />}
    />,
    <Route
        key={1}
        path='/'
        element={<Home />}
    />,
    <Route
        key={2}
        path='/register'
        element={<Register />}
    />,
    <Route
        key={3}
        path='/login'
        element={<Login />}
    />,
    <Route
        key={4}
        path='/logout'
        element={<Logout />}
    />,
    <Route
        key={5}
        path='/forgot'
        element={<Forgot />}
    />,
  ];

  if (currentUser) {
    if (isAdmin) {
      const adminRoutes = [
        <Route
            key={6}
            path='/orders'
            element={<Orders />}
        />,
        <Route
            key={7}
            path='/parts'
            element={<Parts />}
        />,
        <Route
            key={8}
            path='/users'
            element={<Users />}
        />,
        <Route
            key={9}
            path='/filters'
            element={<Filters />}
        />,
        <Route
            key={10}
            path='/categories'
            element={<Category />}
        />,
        <Route
            key={11}
            path='/shopping'
            element={<Shopping />}
        />,
        <Route
            key={13}
            path='/checkout'
            element={<Checkout />}
        />,
        <Route
            key={14}
            path='/cartinventory'
            element={<CartInventory />}
        />,
        <Route
            key={15}
            path='/maintenance'
            element={<ManagerResources />}
        />,
        <Route
            key={16}
            path='/supportdocuments'
            element={<CutSheets />}
        />,
        <Route
            key={17}
            path='/supportdocuments/categories/:category'
            element={<SheetsView />}
        />,
        <Route
            key={18}
            path='/kits'
            element={<Kits />}
        />,
      ];
      routes.push(...adminRoutes);
    } else {
      const userRoutes = [
        <Route
            key={6}
            path='/shopping'
            element={<Shopping />}
        />,
        <Route
            key={8}
            path='/checkout'
            element={<Checkout />}
        />,
        <Route
            key={9}
            path='/orderhistory'
            element={<OrderHistory />}
        />,
        <Route
            key={10}
            path='/maintenance'
            element={<ManagerResources />}
        />,
        <Route
            key={11}
            path='/supportdocuments'
            element={<CutSheets />}
        />,
        <Route
            key={12}
            path='/supportdocuments/categories/:category'
            element={<SheetsView />}
        />,
      ];
      if (isInstall) {
        userRoutes.push(<Route
            key={13}
            path='/cartinventory'
            element={<CartInventory />}
        />);
      }
      routes.push(...userRoutes);
    }
  }

  return (
      <Layout currentUser={currentUser}>
        <SnackbarProvider maxSnack={3}>
          <Routes>
            {routes}
          </Routes>
        </SnackbarProvider>
      </Layout>
  );
};

const mapStateToProps = state => {
  return {
    approved: state.auth.approved,
    added: state.cart.added,
    removed: state.cart.removed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchParts: (email, isAdmin) => dispatch(actions.onFetchParts(email, isAdmin)),
    onFetchCategories: () => dispatch(actions.onFetchCategories()),
    onFetchUsers: () => dispatch(actions.onFetchUsers()),
    getCurrentUser: () => dispatch(actions.getUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
