import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { AppBar, Tabs, Tab } from "@mui/material";

function TabPanel({ children, value, index, ...other })  {
    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Fragment>
                    {children}
                </Fragment>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function CustomTabs({ tabLabelList, handleTabChange, componentsList}) {
    const [value, setValue] = React.useState(0);
    let panelIndex = 0;
    let tabIndex = 0;

    const handleChange = (newValue) => {
        handleTabChange(newValue);
        setValue(newValue);
    };

    return (
        <Fragment>
            <AppBar
                position="static"
                color="default"
            >
                <Tabs
                    value={value}
                    onChange={(event, value) => handleChange(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {tabLabelList.map(label => <Tab key={tabIndex++} label={label} />)}
                </Tabs>
            </AppBar>
            {componentsList.map(component =>
                <TabPanel
                    value={value}
                    index={panelIndex++}
                    key={panelIndex}
                >
                    {component}
                </TabPanel>
            )}
        </Fragment>
    );
}
