import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';

import {Button, Typography} from '@mui/material';

import image from '../../../assets/images/obi-wan.jpg';
import {AuthContext} from '../Auth';
import * as actions from '../../../store/actions';

const Page404 = ({onSaveRedirectUrl}) => {
  const {currentUser} = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(false);

  useEffect(() => {
    const time = setTimeout(() => {
      setError(true);
    }, 1000);
    return () => {
      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    if (!currentUser) {
      onSaveRedirectUrl(location.pathname);
      navigate('/');
    }
  });

  return (
      error ?
          <div style={{textAlign: 'center'}}>
            <Typography variant={'h4'}>This is not the page you are looking for...</Typography>
            <br />
            <img
                src={image}
                alt={'Oops'}
            />
            <br />
            <a href={'/'}>
              <Button
                  color={'primary'}
                  variant={'contained'}
              >
                Return Home
              </Button>
            </a>
          </div>
          :
          <div></div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveRedirectUrl: (redirectUrl) => dispatch(actions.saveRedirectUrl(redirectUrl)),
    onRemoveItem: (id) => dispatch(actions.onRemoveItem(id)),
  };
};

export default connect(null, mapDispatchToProps)(Page404);
