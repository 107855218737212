import * as actionTypes from './actionTypes';

export const fetchItems = () => {
    return {
        type: actionTypes.FETCH_ITEMS
    };
};

export const onAddItem = (item, amount) => {
    return {
        type: actionTypes.ADD_ITEM,
        item: item,
        amount: amount
    };
};

export const onRemoveItem = (id) => {
    return {
        type: actionTypes.REMOVE_ITEM,
        id: id
    };
};

export const onClearItems = () => {
    return {
        type: actionTypes.CLEAR_ITEMS
    }
};

export const onSetItems = (item, amount) => {
    return {
        type: actionTypes.SET_ITEMS,
        item: item,
        amount: amount
    }
};

export const orderComplete = () => {
    return {
        type: actionTypes.ORDER_COMPLETE
    }
};

export const clearOrder = () => {
    return {
        type: actionTypes.CLEAR_ORDER
    }
};
