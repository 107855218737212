import React, {useState} from 'react';

import {Box, Card, CardMedia, FormControl, IconButton, InputAdornment, TextField, Typography} from '@mui/material';
import {Add, Close, Remove} from '@mui/icons-material';

import alt_image from '../../../assets/images/alt_image.jpg';
import formStyle from '../../UI/Styles/formStyle';
import classes from './CartItem.module.css';

const CartItem = ({name, amount, pictureUrl, loading, onAdd, onRemove, onSet, onClearItems}) => {
  const styles = formStyle();
  const pic = pictureUrl ? pictureUrl : alt_image;
  const [nanValue, setNanValue] = useState();

  const setCartAmountOnChange = (amount) => {
    if (amount > 0) {
      onSet(amount);
      if (nanValue) {
        setNanValue(null);
      }
    } else {
      setNanValue('Nan');
    }
  };

  const setCartAmountOnBlur = () => {
    if (nanValue) {
      setNanValue(null);
    }
  };

  return (
      <Card className={classes['cart-item']}>
        <CardMedia
            className={classes.cover}
            loading={'lazy'}
            component='img'
            alt={name}
            image={pic}
        />
        <Box className={classes.content}>
          <div className={classes.description}>
            <Typography variant='h6'>{name}</Typography>
            <IconButton
                onClick={onClearItems}
                disabled={loading}
                className={styles.modalExitButton}
                color={'black'}
            >
              <Close fontSize={'medium'} />
            </IconButton>
          </div>
          <Box className={classes.actions}>
            <FormControl>
              <TextField
                  value={nanValue ? nanValue : amount}
                  variant='standard'
                  disabled={loading}
                  style={{padding: '0 !important'}}
                  onChange={event => setCartAmountOnChange(event.target.valueAsNumber)}
                  onBlur={setCartAmountOnBlur}
                  type={'number'}
                  required
                  fullWidth
                  onInput={(e) => {
                    e.target.value = Math.max(0, e.target.value.slice(0, 5));
                  }}
                  InputProps={{
                    inputProps: {min: 0, max: 10000},
                    endAdornment:
                        <InputAdornment position='end'>
                          <IconButton
                              onClick={onAdd}
                              className={classes.cartItemButton}
                              disabled={loading}
                          >
                            <Add />
                          </IconButton>
                        </InputAdornment>,
                    startAdornment:
                        <InputAdornment position='start'>
                          <IconButton
                              onClick={onRemove}
                              className={classes.cartItemButton}
                              disabled={loading}
                          >
                            {amount === 1 ? <Close /> : <Remove />}
                          </IconButton>
                        </InputAdornment>,
                  }}
              >
              </TextField>
            </FormControl>
          </Box>
        </Box>
      </Card>
  );
};

export default CartItem;
