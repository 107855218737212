import React from "react";

import { Fab } from "@mui/material";
import { AddPhotoAlternate, HighlightOff } from "@mui/icons-material";

const PictureButton = (props) => {
    return (
        props.tempPicture ?
            <Fab component="span" disabled={props.disabled}>
                <HighlightOff onClick={props.handleRemovePictureClick} color={"error"} fontSize={"large"} />
            </Fab>
            :
            <div>
                <input
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={props.handleAddPictureClick}
                />
                <label htmlFor="contained-button-file">
                    <Fab component="span">
                        <AddPhotoAlternate />
                    </Fab>
                </label>
            </div>
    );
};

export default PictureButton;
