import React from 'react';

import {Typography} from '@mui/material';

import * as classes from './Home.module.css';
import Login from '../../Auth/Login/Login';

const Home = () => (
    <div style={{marginTop: '-17px'}}>
      <div className={classes.BackGroundImage}>
        <div className={classes.BlurredBox}>
          <div className={classes.CaptionBox}>
            <Typography className={classes.Title}>
              EC MEMBERS:
            </Typography>
            <Typography className={classes.Title}>
              EQUIPMENT, PARTS, SERVICE & MORE
            </Typography>
            <br />
            <Typography className={classes.TagLine}>
              Let's make sure you have what you need -
            </Typography>
            <Typography className={classes.TagLine}>
              when you need it.
            </Typography>
          </div>
          <br />
          <div className={classes.LoginForm}>
            <Login />
          </div>
        </div>
      </div>
    </div>
);

export default Home;
