import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import { collection, doc, setDoc } from "firebase/firestore";

import { Button, Container, FormControl, Grid, InputLabel, Select, Slide, Typography } from "@mui/material";

import { filterSheetId, firestore } from "../../../../firebase";
import CustomLinearProgress from "../../../../components/UI/LinearProgress/CustomLinearProgress";
import { loadSheetsData } from "../../../../components/UI/Helper/Helper";
import useStyles from "../../../../components/UI/Styles/formStyle";

const Filters = (props) => {
    const styles = useStyles();
    const [jsonData, setJsonData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [partIndex, setPartIndex] = useState("");
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState("");

    useEffect(() => {
        loadSheet();
    }, []);

    function loadSheet() {
        setJsonData([]);
        loadSheetsData(filterSheetId)
            .then(response => {
                setJsonData(JSON.parse(response));
            })
            .catch(error => console.log(error));
    }

    function addPartFilter(row) {
        const usersToFilter = [];
        const filters = Object.keys(row)
            .filter(item => item.includes('@') && row[item].trim() !== "");
        filters.forEach(user => {
            usersToFilter.push(user);
        });

        console.log(usersToFilter);

        const partsDocRef = doc(firestore, "parts", row.id);
        const partsColRef = collection(partsDocRef, "filters")
        const partsColDocRef = doc(partsColRef, "filters");
        setDoc(partsColDocRef, { usersToFilter })
            .catch(error => setError(error));
    }

    function parser() {
        let count = 0;
        setIsLoading(true);
        if (partIndex) {
            addPartFilter(jsonData[partIndex]);
            props.enqueueSnackbar('Filter Created for ' + jsonData[partIndex].name, {
                variant: "success",
                autoHideDuration: 6000,
                TransitionComponent: Slide,
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
        } else {
            for (let i = 0; i < jsonData.length; i++) {
                const row = jsonData[i];
                let progress = ((i + 1) / jsonData.length) * 100;
                setProgress(progress);
                count++;
                addPartFilter(row);
                if (count % 400 === 0) { //let firebase cool down
                    setTimeout(function () {
                        console.log("timeout")
                    }, 10000);
                }
            }

            props.enqueueSnackbar('Filters created for ' + jsonData.length + ' parts', {
                variant: "success",
                autoHideDuration: 6000,
                TransitionComponent: Slide,
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
            });
        }
        setIsLoading(false);
    }

    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Select a part to create filters for the specified part only, or leave empty to create
                        filters for every part.</Typography>
                </Grid>
                {jsonData.length > 0 &&
                    <Grid item xs={12}>
                        <FormControl variant="outlined" className={styles.formControl}>
                            <InputLabel required>Part to Filter</InputLabel>
                            <Select
                                native
                                value={partIndex}
                                onChange={event => setPartIndex(event.target.value)}
                                label="Part to Filter"
                            >
                                <option value="" />
                                {jsonData.map((listItem, index) => {
                                    return (
                                        <option key={listItem.id} value={index}>{listItem.name}</option>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>}
                <Grid item xs={12}>
                    <Button
                        onClick={loadSheet}
                        fullWidth
                        variant="contained"
                        color={"success"}
                    >
                        Refresh Data
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={() => parser()}
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isLoading || jsonData.length < 1}
                    >
                        {"Create Filter" + (partIndex ? "" : "s")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {isLoading && <CustomLinearProgress value={progress} />}
                </Grid>
                <Grid item xs={12}>
                    {error && <Typography color={"error"}>{error}</Typography>}
                </Grid>
            </Grid>
        </Container>
    );
};

export default (withSnackbar(Filters))
