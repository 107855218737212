import React, { useEffect, useState } from "react";
import { collection, deleteDoc, doc, getDocs, orderBy, query } from "firebase/firestore";

import { Container } from "@mui/material";

import { firestore, storage } from "../../../../firebase";
import EnhancedTable from "../../../../components/UI/Table/Table";
import TransitionModal from "../../../../components/UI/Modal/Modal";
import AddCategory from "./Forms/AddCategory/AddCategory";
import EditPartForm from "./Forms/EditCategory/EditCategory";
import DeleteForm from "../../../../components/UI/Forms/DeleteForm/DeleteForm";
import { ref, deleteObject } from "firebase/storage";

const headCells = [
    { id: 'name', label: 'Name' },
];

const Category = () => {
    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        //TODO use redux
        reloadCategories();
        console.log("Got categories");
    }, []);

    async function getCategories() {
        let categories = [];
        const categoryRef = query(collection(firestore, "categories"), orderBy("name", "asc"));
        const querySnapshot = await getDocs(categoryRef);
        querySnapshot.forEach((doc) => {
            categories.push({ ...doc.data(), id: doc.id });
        });
        setTableData(categories);
    }

    const reloadCategories = () => {
        getCategories()
            .catch(error => {
                console.log(error)
            });
    };

    //add modal functions
    const onAddCategory = () => {
        reloadCategories();
        handleAddClose();
    };

    const handleAddOpen = () => {
        setAddOpen(true);
    };

    const handleAddClose = () => {
        setAddOpen(false);
    };

    //edit modal functions
    function onEditCategory() {
        reloadCategories();
        handleEditClose();
    }

    const handleEditOpen = (props) => {
        setFormData({ ...props });
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    //delete modal functions
    const onDeleteCategory = async (id) => {
        //TODO delete category should worn of parts hooked to it.
        let pictureError = false;
        if (formData.pictureUrl) {
            await deletePhotoFromStorage(id)
                .catch((error) => {
                    pictureError = true;
                    console.log(error);
                });
        }
        if (!pictureError) {
            deleteDoc(doc(firestore, "categories", id))
                .then(() => {
                    reloadCategories();
                    handleDeleteClose();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const deletePhotoFromStorage = (id) => {
        try {
            const deleteRef = ref(storage, "categories/" + id)
            return new Promise((resolve, reject) => {
                deleteObject(deleteRef)
                    .then(() => {
                        resolve("success");
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const handleDeleteOpen = (props) => {
        setFormData({ ...props });
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    return (
        <Container>
            <EnhancedTable
                data={tableData}
                headCells={headCells}
                add={handleAddOpen}
                actionEdit={handleEditOpen}
                actionDelete={handleDeleteOpen}
                actions
            />
            <TransitionModal
                open={addOpen}
                handleOpen={handleAddOpen}
                handleClose={handleAddClose}
                form={<AddCategory onAdd={onAddCategory} />}
                title={"Add Category"}
                alignTop
            />
            <TransitionModal
                open={editOpen}
                handleOpen={handleEditOpen}
                handleClose={handleEditClose}
                form={<EditPartForm formData={formData} onEdit={onEditCategory}
                                    deletePhotoFromStorage={deletePhotoFromStorage} />}
                title={"Edit Category"}
                alignTop
            />
            <TransitionModal
                open={deleteOpen}
                handleOpen={handleDeleteOpen}
                handleClose={handleDeleteClose}
                form={<DeleteForm formData={formData} onDelete={onDeleteCategory}
                                  title={"Delete " + formData.name + " category?"} buttonText={"Delete Category"} />}
                title={"Are You Sure?"}
            />
        </Container>
    );
};

export default Category;
