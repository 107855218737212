import React from "react";
import { connect } from "react-redux";

import { Container, Grid } from "@mui/material";

import CategoryCard from "../Shopping/Cards/CategoryCard/CategoryCard";

const CutSheets = ({ cutSheetCategories }) => {
    return (
        <Container>
            <Grid
                container
                spacing={2}
            >
                {cutSheetCategories.map(item => {
                    return (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            key={item.id}
                        >
                            <CategoryCard
                                name={item.category}
                                pictureUrl={item.pictureUrl}
                                to={'/supportdocuments/categories/' + item.category}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        cutSheetCategories: state.cutSheets.cutSheetCategories,
    };
};

export default connect(mapStateToProps)(CutSheets);
