import React, {Fragment, useContext, useState} from 'react';

import {AppBar, IconButton, Toolbar} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import {AuthContext} from '../../../containers/Auth/Auth';
import TransitionModal from '../../UI/Modal/Modal';
import Cart from '../../Cart/Cart';
import classes from './AppBar.module.css';
import EcIcon from '../../../assets/icons/EcIcon/EcIcon';
import SearchBar from '../../UI/Search/SearchBar/SearchBar';
import SideDrawer from '../SideDrawer';
import HeaderCartButton from '../HeaderCartButton/HeaderCartButton';
import EcIconWords from '../../../assets/icons/EcIconWords/EcIconWords';

export default function CustomAppBar() {
  const {currentUser} = useContext(AuthContext);
  const [drawerState, setDrawerState] = useState(false);
  const [modalState, setModalState] = useState(false);

  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  const toggleModal = () => {
    setModalState(!modalState);
  };

  return (
      <Fragment>
        <AppBar
            position="static"
            className={classes.Color}
        >
          <Toolbar className={classes.ToolBar}>
            <div className={classes.LeftContent}>
              <a href={'https://www.expresscarwash.com'}>
                <EcIcon />
              </a>
              <EcIconWords className={currentUser && classes.IconWords} />
            </div>
            {currentUser &&
                <div className={classes.RightContent}>
                  <div className={classes.Search}>
                    <SearchBar />
                  </div>
                  <div className={classes.Button}>
                    <HeaderCartButton open={toggleModal} />
                  </div>
                  <IconButton
                      edge="start"
                      color="inherit"
                      onClick={toggleDrawer}
                  >
                    <MenuIcon fontSize={'large'} />
                  </IconButton>
                </div>
            }
          </Toolbar>
        </AppBar>
        <SideDrawer
            toggleDrawer={toggleDrawer}
            drawerState={drawerState}
        />
        <TransitionModal
            open={modalState}
            handleClose={toggleModal}
            form={<Cart close={toggleModal} />}
            title={'Cart'}
            maxWidth={'md'}
        />
      </Fragment>
  );
}
