import { useEffect, useState } from "react";
import { getDocs } from "firebase/firestore";

export default function useFetch(firebaseRef) {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            let tempData = [];
            setLoading(true);
            const querySnapshot = await getDocs(firebaseRef)
            querySnapshot.forEach(doc => {
                tempData.push({ ...doc.data(), id: doc.id });
            });
            setLoading(false);
            setData(tempData);
        })();
    }, []);

    return [data, isLoading];
}
