import React, { Fragment } from "react";

import { Button, Step, StepLabel, Stepper, Typography } from "@mui/material";

export default function CustomStepper({ statusList, status, setStatus }) {
    const steps = statusList;

    const handleNext = () => {
        setStatus((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setStatus((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setStatus(1);
    };

    return (
        <Fragment>
            <Stepper activeStep={status} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <br />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                {status === steps.length ? (
                    <Fragment>
                        <Typography>Order completed</Typography>
                        <Button onClick={handleReset} color={"secondary"}>Reset</Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button disabled={status === 1} onClick={handleBack}>
                            Undo
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                        >
                            {status === steps.length - 1 ? 'Complete Order' : 'Advance Status'}
                        </Button>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}
