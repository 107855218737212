import React, { Fragment } from "react";

import CartDrawer from "./CartDrawer";

const Cart = ({ drawers, setDrawers }) => {
    const handleOrderRequiredAmount = (row, index) => {
        const drawersUpdate = [...drawers];
        drawersUpdate[row.drawerCount].items[index] = {
            ...drawersUpdate[row.drawerCount].items[index],
            quantityOnCart: row.requiredQuantity,
            quantityNeeded: 0
        };
        setDrawers(drawersUpdate);
    };

    const handleQuantityChanged = (row, value, index) => {
        const drawersUpdate = [...drawers];
        let positiveAmount;
        if (value > 0) {
            positiveAmount = value
        } else {
            positiveAmount = 0;
        }
        drawersUpdate[row.drawerCount].items[index] = {
            ...drawersUpdate[row.drawerCount].items[index],
            quantityOnCart: isNaN(value) ? value.toString() : positiveAmount,
            quantityNeeded: row.requiredQuantity - positiveAmount
        };
        setDrawers(drawersUpdate);
    }

    return (
        <Fragment>
            {drawers.map((drawer, index) =>
                <CartDrawer
                    key={index}
                    drawer={drawer}
                    handleOrderRequiredAmount={handleOrderRequiredAmount}
                    handleQuantityChanged={handleQuantityChanged}
                />)}
        </Fragment>
    )
}

export default Cart;
