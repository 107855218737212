import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Autocomplete, IconButton, TextField} from '@mui/material';
import {Search} from '@mui/icons-material';

import useStyles from '../../Styles/formStyle';
import SearchModal from '../SearchModal/SearchModal';

const SearchBar = (props) => {
  const {parts} = props;
  const styles = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchItem, setSearchItem] = useState(null);
  const [searchItemText, setSearchItemText] = useState('');

  const handleSearchOnChange = (value) => {
    if (value) {
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    handleClearItem();
  };

  const handleClearItem = () => {
    setSearchItem(null);
    setSearchItemText('');
  };

  return (
      <div className={styles.toolBarSearchBar}>
        <div style={{width: '100%'}}>
          <Autocomplete
              freeSolo
              value={searchItem}
              onChange={(event, newValue) => {
                setSearchItem(newValue);
                handleSearchOnChange(newValue);
              }}
              inputValue={searchItemText}
              onInputChange={(event, newInputValue) => {
                setSearchItemText(newInputValue);
              }}
              options={parts.sort((a, b) => -b.name.charAt(0).localeCompare(a.name.charAt(0)))}
              groupBy={(option) => option.name.charAt(0)}
              getOptionLabel={(option) => (option.name)}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      variant={'outlined'}
                      placeholder={'Search'}
                  >

                  </TextField>
              )}
          />
        </div>
        <IconButton
            className={styles.searchIconButton}
            disabled={true}
        >
          <Search />
        </IconButton>
        <SearchModal
            open={modalOpen}
            close={handleModalClose}
            item={searchItem}
        />
      </div>
  );
};

const mapStateToProps = state => {
  return {
    parts: state.parts.parts,
  };
};

export default connect(mapStateToProps)(SearchBar);
