import React from "react";

import { Card, Typography } from "@mui/material";

const Item = ({ item }) => (
    <Card style={{ padding: '4px', boxShadow: '5px 5px 5px lightgrey !important', border: '1px solid lightgrey' }}
          key={item.id}>
        <Typography>
            {item.name} {item.amount &&
            <span><span style={{ fontWeight: 'bold' }}> X </span>{item.amount}</span>}
        </Typography>
    </Card>
);

export default Item;
