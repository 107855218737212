import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = JSON.parse(process.env.REACT_APP_PROD_CONFIG);
// const firebaseConfig = JSON.parse(process.env.REACT_APP_STAGING_CONFIG);
// const firebaseConfig = JSON.parse(process.env.REACT_APP_INSTALL_CONFIG);
const firebase = initializeApp(firebaseConfig);

export default firebase;
export const auth = getAuth();
export const functions = getFunctions(firebase);
export const firestore = getFirestore(firebase);
export const analytics = getAnalytics(firebase);
export const storage = getStorage(firebase);
export const storageRef = ref(storage);
export const passwordResetUrl = firebaseConfig.passwordResetUrl;
export const filterSheetId = firebaseConfig.filterSheetId;
