import React from 'react';

import {SwipeableDrawer} from '@mui/material';

import EcIcon from '../../assets/icons/EcIcon/EcIcon';
import NavigationItems from './NavigationItems/NavigationItems';

const SideDrawer = ({toggleDrawer, drawerState}) => (
    <SwipeableDrawer
        anchor={'right'}
        open={drawerState}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        style={{display: 'flex'}}
    >
      <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
        <EcIcon />
      </div>
      <div
          style={{width: 250}}
          onClick={toggleDrawer}
      >
        <NavigationItems />
      </div>
    </SwipeableDrawer>
);

export default SideDrawer;
