import React, { useState } from "react";
import { httpsCallable } from "firebase/functions";

import { Button, Checkbox, Divider, FormControlLabel, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import formStyles from "../../../../../../components/UI/Styles/formStyle";
import { functions } from "../../../../../../firebase";

const Edit = props => {
    const { formData } = props;
    const [first, setFirst] = useState(formData.first);
    const [last, setLast] = useState(formData.last);
    const [city, setCity] = useState(formData.city);
    const [state, setState] = useState(formData.state);
    const [zip, setZip] = useState(formData.zip);
    const [address, setAddress] = useState(formData.address);
    const [locationPhone, setLocationPhone] = useState(formData.locationPhone);
    const [managerPhone, setManagerPhone] = useState(formData.managerPhone);
    const [nickName, setNickName] = useState(formData.nickName ? formData.nickName : "");
    const [approved, setApproved] = useState(formData.approved === "true");
    const [isLoading, setLoading] = useState(false);
    const classes = formStyles();

    const submitHandler = (event) => {
        event.preventDefault();
        const tempFirst = first.trim()
            .charAt(0)
            .toUpperCase() + first.trim()
            .slice(1);
        const tempLast = last.trim()
            .charAt(0)
            .toUpperCase() + last.trim()
            .slice(1);

        const formData = {
            ...props.formData,
            city,
            state,
            zip,
            address,
            locationPhone,
            first: tempFirst,
            last: tempLast,
            managerPhone,
            nickName,
            approved: `${approved}`
        };

        setLoading(true);
        if (props.formData.approved === formData.approved) {
            props.onEdit(formData, props.formData.email);
        } else {
            const updateUserApproval = httpsCallable(functions, 'updateUserApproval');
            updateUserApproval({ disabled: !approved, email: props.formData.email })
                .then(() => {
                    props.onEdit(formData, props.formData.email);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        }
    };

    return (
        <form className={classes.form} onSubmit={submitHandler}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Typography>Carwash Location Info</Typography>
                    <Divider />
                </Grid>
                <Grid xs={12} sm={6}>
                    <TextField
                        value={city}
                        onChange={event => setCity(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="city"
                        label="City"
                        autoFocus
                    />
                </Grid>
                <Grid xs={12} sm={2}>
                    <TextField
                        value={state}
                        onChange={event => setState(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="state"
                        label="State"
                    />
                </Grid>
                <Grid xs={12} sm={4}>
                    <TextField
                        value={zip}
                        onChange={event => setZip(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="zip"
                        label="Zip Code"
                    />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        value={address}
                        onChange={event => setAddress(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="address"
                        label="Address"
                        inputProps={{ maxLength: 63 }}
                    />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        value={locationPhone}
                        onChange={event => setLocationPhone(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="phone"
                        label="Phone Number"
                    />
                </Grid>
                <Grid xs={12}>
                    <Typography>General Manager Contact Info</Typography>
                    <Divider />
                </Grid>
                <Grid xs={12} sm={6}>
                    <TextField
                        value={first}
                        onChange={event => setFirst(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                    />
                </Grid>
                <Grid xs={12} sm={6}>
                    <TextField
                        value={last}
                        onChange={event => setLast(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                    />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        value={managerPhone}
                        onChange={event => setManagerPhone(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="mobile"
                        label="Phone Number"
                    />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        value={nickName}
                        onChange={event => setNickName(event.target.value)}
                        variant="outlined"
                        required
                        fullWidth
                        id="nickname"
                        label="Nickname"
                    />
                </Grid>
                <Grid xs={12}>
                    <FormControlLabel
                        className={classes.formControl}
                        checked={approved}
                        value={approved}
                        onChange={() => setApproved(!approved)}
                        control={<Checkbox value={approved} color="primary" />}
                        label="Approved"
                    />
                </Grid>
                {props.error &&
                    <Grid xs={12}>
                        {props.error}
                    </Grid>
                }
                <Grid xs={6}>
                    <Button
                        disabled={isLoading}
                        onClick={props.handleClose}
                        fullWidth
                        color={"secondary"}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid xs={6}>
                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Save User
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default Edit;
