import { collection, deleteDoc, doc, getDocs, query, updateDoc, } from "firebase/firestore";

import * as actionTypes from './actionTypes';
import { firestore } from "../../firebase";

async function getUsers() {
    let users = [];
    const userRef = query(collection(firestore, "users"))
    const querySnapshot = await getDocs(userRef);
    querySnapshot.forEach((user) => {
        users.push({ ...user.data(), id: user.id });
    });
    return users;
}

export const onFetchUsers = () => {
    return dispatch => {
        dispatch(usersStart());
        getUsers()
            .then((users) => {
                dispatch(usersSuccess());
                dispatch(setUsers(users));
            })
            .catch(error => {
                usersFail(error)
            });
    }
};

export const usersStart = () => {
    return {
        type: actionTypes.USERS_START
    };
};

export const usersSuccess = () => {
    return {
        type: actionTypes.USERS_SUCCESS
    };
};

export const usersFail = (error) => {
    return {
        type: actionTypes.USERS_FAIL,
        error: error
    };
};

export const onAddUser = (user) => {
    return {
        type: actionTypes.ADD_USER,
        user: user
    };
};

export const editUser = (user, id) => {
    return {
        type: actionTypes.EDIT_USER,
        user: user,
        id: id
    };
};

export const onEditUser = (user, id) => {
    return dispatch => {
        dispatch(usersStart());
        updateDoc(doc(firestore, "users", id), user)
            .then(() => {
                dispatch(usersSuccess());
                dispatch(editUser(user, id));
            })
            .catch(error => {
                dispatch(usersFail(error));
            });
    }
};


export const removeUser = (id) => {
    return {
        type: actionTypes.REMOVE_USER,
        id: id
    };
};

export const onRemoveUser = (id) => {
    return dispatch => {
        dispatch(usersStart());
        deleteDoc(doc(firestore, "users", id))
            .then(() => {
                dispatch(usersSuccess());
                dispatch(removeUser(id));
            })
            .catch(error => {
                dispatch(usersFail(error));
            });
    }
};

export const setUsers = (users) => {
    return {
        type: actionTypes.SET_USERS,
        users: users
    };
};
