import React, {Fragment, useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ref, uploadBytesResumable} from 'firebase/storage';
import {collection, doc, serverTimestamp, setDoc} from 'firebase/firestore';

import {Button, Card, Container, FormControl, InputLabel, Select, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import useStyles from '../../../../../components/UI/Styles/formStyle';
import {firestore, storage} from '../../../../../firebase';
import CartItem from '../../../../../components/Cart/CartItem/CartItem';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import * as actions from '../../../../../store/actions';
import * as classes from './Checkout.module.css';
import {AuthContext} from '../../../../Auth/Auth';
import alt_image from '../../../../../assets/images/alt_image.jpg';
import ControlledPagination from '../../../../../components/UI/Pagination/ControlledPagination';
import PictureButton from '../../../../../components/UI/Buttons/PictureButton';
import {compressFile, handleFileUpload} from '../../../../../components/UI/Helper/Helper';
import CustomLinearProgress from '../../../../../components/UI/LinearProgress/CustomLinearProgress';

const Checkout = (props) => {
  const {items, email, nickName} = props;
  const {isAdmin} = useContext(AuthContext);
  const styles = useStyles();
  const navigate = useNavigate();
  const [name, setName] = useState(props.managerFirst + ' ' + props.managerLast);
  const [phoneNumber, setPhoneNumber] = useState(props.locationPhone);
  const [shippingMethod, setShippingMethod] = useState('Ground');
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [customerIndex, setCustomerIndexIndex] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [tempPicture, setTempPicture] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const numberPerPage = 10;

  useEffect(() => {
    //only set number if it's less than numberPerPage, otherwise let the pagination component set
    if (items && items.length <= numberPerPage) {
      setCartItems(items);
    }
  }, [items]);

  const submitOrderHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let adminEmail = '';
    let adminNickName = '';
    if (isAdmin) {
      const customer = props.users[customerIndex];
      adminEmail = customer.email;
      adminNickName = customer.nickName;
    }

    let cleanedItems = [];
    items.forEach(item => {
      let cleanedItem = {
        itemId: item.id,
        amount: item.amount,
      };
      cleanedItems.push(cleanedItem);
    });

    let pictureError = false;
    let pictures = [];
    const orderRef = doc(collection(firestore, 'orders'));

    if (file) {
      const pictureStoragePath = 'orders/' + orderRef.id;
      const storageRef = ref(storage, pictureStoragePath);
      const uploadTask = uploadBytesResumable(storageRef, file);
      await handleFileUpload(uploadTask, setProgress).
          then(function(pictureUrl) {
            pictures.push({pictureUrl, pictureStoragePath});
          }).
          catch(function(error) {
            setError(error.message);
            pictureError = true;
            setIsLoading(false);
          });
    }

    const timestamp = serverTimestamp();
    const order = {
      status: 'new',
      statusStep: 1,
      createdAt: timestamp,
      updatedAt: timestamp,
      nickName: isAdmin ? adminNickName : nickName,
      email: isAdmin ? adminEmail : email,
      comment,
      pictures,
      items: cleanedItems,
      shippingMethod,
    };

    setDoc(orderRef, order).then(() => {
      const timeoutLength = (Math.random() * 700) + 300;
      setTimeout(function() {
        if (isAdmin) {
          navigate('/orders');
        } else {
          navigate('/orderhistory');
        }
        props.orderComplete();
        props.onClearItems();
      }, timeoutLength);
    }).catch(error => console.log(error));
  };

  const shippingOptions = [
    'Ground',
    'Next Day Air',
    'With Next EC Detergent Delivery',
    'Send With Eddy',
  ];

  //functions for showing a temporary photo before an actual upload
  const handleAddPictureClick = async (event) => {
    const file = event.target.files[0];
    setIsLoading(true);
    await compressFile(file, true).
        then(compressedResult => setFile(compressedResult)).
        catch(error => setError(error));
    setIsLoading(false);
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function() {
      setTempPicture(reader.result);
    };
  };

  const handleRemovePictureClick = () => {
    setTempPicture(null);
    setFile(null);
  };

  const addPictureComponent = (
      <Fragment>
        <Grid
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
        >
          <PictureButton
              tempPicture={tempPicture}
              handleRemovePictureClick={handleRemovePictureClick}
              handleAddPictureClick={handleAddPictureClick}
              disabled={isLoading}
          />
        </Grid>
        <Grid
            xs={9}
            style={{outline: '1px dotted lightgray', outlineOffset: '-8px'}}
        >
          {tempPicture &&
              <img
                  src={tempPicture}
                  alt={'error'}
                  style={{
                    margin: 'auto',
                    display: 'block',
                    padding: 'inherit',
                    maxHeight: 129,
                  }}
              />
          }
        </Grid>
        {(isLoading || progress === 100) &&
            <Grid xs={12}>
              <CustomLinearProgress value={progress} />
            </Grid>}
        {error &&
            <Grid xs={12}>
              <Typography color={'error'}>{error}</Typography>
            </Grid>}
      </Fragment>
  );

  const customerForm = (
      <Fragment>
        <Grid xs={12}>
          <TextField
              value={name}
              onChange={event => setName(event.target.value)}
              variant='outlined'
              required
              fullWidth
              id='name'
              label='Customer Name'
              autoFocus
              inputProps={{maxLength: 63}}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
              value={phoneNumber}
              onChange={event => setPhoneNumber(event.target.value)}
              variant='outlined'
              required
              fullWidth
              id='phoneNumber'
              label='Customer Phone Number'
              inputProps={{maxLength: 10}}
          />
        </Grid>
        <Grid xs={12}>
          <FormControl
              variant='outlined'
              className={styles.formControl}
              required
          >
            <InputLabel>Shipping Method</InputLabel>
            <Select
                native
                value={shippingMethod}
                onChange={event => setShippingMethod(event.target.value)}
                label='Shipping Method'
            >
              <option
                  value=''
                  style={{display: 'none'}}
              />
              {shippingOptions.map((listItem, index) => {
                return (
                    <option
                        key={index}
                        value={listItem}
                    >{listItem}</option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {addPictureComponent}
        <Grid xs={12}>
          <TextField
              autoFocus
              value={comment}
              onChange={event => setComment(event.target.value)}
              id='outlined-textarea'
              label='Comments'
              multiline
              variant='outlined'
              fullWidth
              minRows={4}
              inputProps={{className: styles.textarea, maxLength: 1024}}
          />
        </Grid>
        <Grid
            xs={10}
            style={{margin: 'auto'}}
        >
          <Button
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              className={styles.formControl}
              disabled={items.length < 1}
          >
            Order
          </Button>
        </Grid>
      </Fragment>
  );

  const adminForm = (
      <Fragment>
        <Grid xs={12}>
          <FormControl
              variant='outlined'
              className={styles.formControl}
              required
          >
            <InputLabel>Customer</InputLabel>
            <Select
                native
                value={customerIndex}
                onChange={event => setCustomerIndexIndex(event.target.value)}
                label='Customer'
            >
              <option
                  value=''
                  style={{display: 'none'}}
              />
              {props.users.filter(user => user.approved === 'true').
                  map((listItem, index) => {
                    return (
                        <option
                            key={listItem.nickName}
                            value={index}
                        >{listItem.nickName}</option>
                    );
                  })}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl
              variant='outlined'
              className={styles.formControl}
              required
          >
            <InputLabel>Shipping Method</InputLabel>
            <Select
                native
                value={shippingMethod}
                onChange={event => setShippingMethod(event.target.value)}
                label='Shipping Method'
            >
              <option
                  value=''
                  style={{display: 'none'}}
              />
              {shippingOptions.map((listItem, index) => {
                return (
                    <option
                        key={index}
                        value={listItem}
                    >{listItem}</option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {addPictureComponent}
        <Grid xs={12}>
          <TextField
              autoFocus
              value={comment}
              onChange={event => setComment(event.target.value)}
              id='outlined-textarea'
              label='Comments'
              multiline
              variant='outlined'
              fullWidth
              minRows={4}
              inputProps={{className: styles.textarea, maxLength: 1024}}
          />
        </Grid>
        <Grid
            xs={10}
            style={{margin: 'auto'}}
        >
          <Button
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              className={styles.formControl}
              disabled={items.length < 1}
          >
            Order
          </Button>
        </Grid>
      </Fragment>
  );

  return (
      <Container className={styles.container}>
        <Grid
            container
            spacing={3}
        >
          <Grid
              xs={12}
              md={8}
          >
            <Card className={classes.Card}>
              <div className={styles.gridPadding}>
                <Typography
                    variant={'h5'}
                    className={classes.Title}
                >
                  {/*<Bubble left={1} size={18} />*/}
                  {/*<Bubble left={90} size={20} />*/}
                  {/*<Bubble left={2} />*/}
                  {/*<Bubble left={90} top={33} />*/}
                  ORDER SUMMARY
                </Typography>
                <Grid
                    container
                    spacing={1}
                    sx={{
                      maxHeight: '65vh !important',
                      overflow: 'auto !important',
                      padding: '0 !important',
                    }}
                >
                  {cartItems.length > 0 ?
                      cartItems.map((item) => (
                          <Grid
                              xs={12}
                              key={item.id}
                          >
                            <CartItem
                                pictureUrl={item.pictures.length > 0
                                    ? item.pictures[0].pictureUrl
                                    : alt_image}
                                amount={item.amount}
                                name={item.name}
                                loading={isLoading}
                                onAdd={() => props.onAddItem(item, 1)}
                                onSet={(amount) => props.onSetItems(item,
                                    amount)}
                                onRemove={() => props.onRemoveItem(item.id)}
                                onClearItems={() => props.onSetItems(item, 0)}
                            />
                          </Grid>
                      ))
                      :
                      <Grid xs={12}>
                        <span className={styles.boldText}>Cart is empty</span>
                      </Grid>
                  }
                </Grid>
                {items.length > numberPerPage &&
                    <Fragment>
                      <br />
                      <ControlledPagination
                          items={items}
                          loading={isLoading}
                          setItems={setCartItems}
                          numberPerPage={numberPerPage}
                      />
                    </Fragment>
                }
              </div>
            </Card>
          </Grid>
          <Grid
              xs={12}
              md={4}
          >
            <Card className={classes.Card}>
              <form
                  onSubmit={submitOrderHandler}
                  className={styles.gridPadding}
              >
                <Typography
                    variant={'h5'}
                    className={classes.Title}
                >
                  {/*<Bubble left={2} size={30} />*/}
                  {/*<Bubble left={80} />*/}
                  CUSTOMER INFO
                </Typography>
                <Grid
                    container
                    spacing={2}
                    sx={{padding: '0 !important'}}
                >
                  {isLoading ? <Spinner /> : (isAdmin
                      ? adminForm
                      : customerForm)}
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Container>
  );
};

const mapStateToProps = state => {
  return {
    items: state.cart.items,
    email: state.auth.email,
    managerFirst: state.auth.first,
    managerLast: state.auth.last,
    nickName: state.auth.nickName,
    locationPhone: state.auth.locationPhone,
    users: state.users.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddItem: (item, amount) => dispatch(actions.onAddItem(item, amount)),
    onRemoveItem: (id) => dispatch(actions.onRemoveItem(id)),
    onSetItems: (item, amount) => dispatch(actions.onSetItems(item, amount)),
    onClearItems: () => dispatch(actions.onClearItems()),
    orderComplete: () => dispatch(actions.orderComplete()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
