import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Button} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {ArrowBackIos, RemoveCircleOutline, Shop} from '@mui/icons-material';

import CartItem from './CartItem/CartItem';
import * as actions from '../../store/actions/index';
import classes from './Cart.module.css';
import alt_image from '../../assets/images/alt_image.jpg';
import ControlledPagination from '../UI/Pagination/ControlledPagination';

const Cart = ({items, onAddItem, onRemoveItem, onClearItems, onSetItems, close}) => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const hasItems = items.length > 0;
  const numberPerPage = 10;

  useEffect(() => {
    //only set number if it's less than numberPerPage, otherwise let the pagination component set
    if (items && items.length <= numberPerPage) {
      setCartItems(items);
    }
  }, [items]);

  const handleCheckoutClick = () => {
    close();
    navigate('/checkout');
  };

  return (
      <Fragment>
        <Grid
            container
            spacing={1}
            sx={{maxHeight: '60vh !important'}}
        >
          {cartItems.length > 0 ?
              cartItems.map((item) => (
                  <Grid
                      xs={12}
                      key={item.id}
                  >
                    <CartItem
                        pictureUrl={item.pictures.length > 0 ? item.pictures[0].pictureUrl : alt_image}
                        amount={item.amount}
                        name={item.name}
                        onAdd={() => onAddItem(item, 1)}
                        onSet={(amount) => onSetItems(item, amount)}
                        onRemove={() => onRemoveItem(item.id)}
                        onClearItems={() => onSetItems(item, 0)}
                    />
                  </Grid>
              ))
              :
              <Grid
                  xs={12}
                  className={classes.Bold}
              >
                <span>Cart is empty</span>
              </Grid>
          }
        </Grid>
        <br />
        <Grid
            container
            spacing={1}
        >
          <Grid xs={12}>
            {items.length > numberPerPage &&
                <ControlledPagination
                    items={items}
                    setItems={setCartItems}
                    numberPerPage={numberPerPage}
                />
            }
          </Grid>
          <Grid
              xs={hasItems ? 4 : 12}
              sm={hasItems ? 6 : 12}
          >
            <Button
                startIcon={<ArrowBackIos className={classes.ButtonIcons} />}
                onClick={close}
                variant={'outlined'}
                color={'secondary'}
                className={classes.CartButton}
                style={{width: '100%'}}
            >
              <span className={hasItems ? classes.CartButtonText : ''}>Continue Shopping</span>
            </Button>
          </Grid>
          {hasItems &&
              <Grid
                  xs={4}
                  sm={hasItems ? 6 : 12}
              >
                <Button
                    startIcon={<RemoveCircleOutline className={classes.ButtonIcons} />}
                    onClick={onClearItems}
                    variant={'outlined'}
                    className={classes.CartButton}
                    color={'secondary'}
                    style={{width: '100%'}}
                >
                  <span className={classes.CartButtonText}>Clear Cart</span>
                </Button>
              </Grid>
          }
          {hasItems &&
              <Grid
                  xs={4}
                  sm={12}
              >
                <Button
                    startIcon={<Shop className={classes.ButtonIcons} />}
                    variant={'contained'}
                    style={{width: '100%', whiteSpace: 'nowrap'}}
                    className={classes.CartButton}
                    color={'primary'}
                    onClick={handleCheckoutClick}
                >
                  <span className={classes.CartButtonText}>Check Out</span>
                </Button>
              </Grid>
          }
        </Grid>
      </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    items: state.cart.items,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddItem: (item, amount) => dispatch(actions.onAddItem(item, amount)),
    onRemoveItem: (id) => dispatch(actions.onRemoveItem(id)),
    onSetItems: (item, amount) => dispatch(actions.onSetItems(item, amount)),
    onClearItems: () => dispatch(actions.onClearItems()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
