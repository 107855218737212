import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(6) + ' !important',
        display: 'flex !important',
        flexDirection: 'column !important',
        alignItems: 'center !important',
    },
    avatar: {
        margin: theme.spacing(1)  + ' !important',
        backgroundColor: theme.palette.secondary.main + ' !important',
    },
    form: {
        display: 'flex !important',
        flexDirection: 'column !important',
        alignItems: 'center !important',
    },
    submit: {
        minWidth: '100% !important'
    },
    formControl: {
        minWidth: '200px !important',
        width: '100%',
    },
    textarea: {
        resize: 'both !important'
    },
    gridPadding: {
        padding: '16px !important'
    },
    boldText: {
        fontWeight: 'bold !important',
        fontSize: '1.5rem !important'
    },
    visuallyHidden: {
        display: 'none !important'
    },
    container: {
        textAlign: 'center !important',
        alignItems: 'center !important',
    },
    title: {
        flexGrow: '1 !important',
    },
    toolBarSearchBar: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '15em',
        marginLeft: 'auto',
    },
    searchBar: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '35em',
        margin: 'auto',
    },
    searchInput: {
        flex: '1 !important',
        '& fieldset': {
            border: 'none !important'
        }
    },
    searchIconButton: {
        marginLeft: -40
    },
    filterSwitch: {
        flexDirection: 'column !important',
        whiteSpace: 'nowrap !important',
        marginRight: '0px !important',
        marginLeft: '0px !important'
    },
    modalExitButton: {
        cursor: 'pointer !important',
        position: 'absolute !important',
        left: 'calc(100% - 36px) !important',
        top: '0 !important',
        zIndex: '1 !important',
        backgroundColor: 'white !important',
        borderRadius: '25px !important',
        padding: 6,
        pointerEvents: 'auto !important',
        '&:disabled': {
            cursor: 'not-allowed !important',
        }
    },
}));

export default useStyles;
