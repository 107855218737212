import React from 'react';

import ItemCard from '../Cards/ItemCard/ItemCard';

const ItemView = ({parts, index}) => (
    <div
        key={index}
        style={{display: 'flex', rowGap: 15, flexDirection: 'column', flex: 1, borderTop: '1px solid black'}}
    >
      {parts.map((item, index) => <ItemCard
          key={index}
          item={item}
      />)}
    </div>
);

export default ItemView;
