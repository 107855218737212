import React, {useContext} from 'react';

import {Divider, List} from '@mui/material';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Build,
  BurstMode,
  Category,
  Description,
  FilterList,
  History,
  Home,
  HomeRepairService,
  Person,
  ShoppingCart,
  WorkOutline,
} from '@mui/icons-material';

import NavigationItem from './NavagationItem/NavigationItem';
import {AuthContext} from '../../../containers/Auth/Auth';

const NavigationItems = () => {
  const {currentUser, isAdmin, isInstall} = useContext(AuthContext);

  const nav = currentUser ?
      <NavigationItem
          link='/logout'
          text='Logout'
          icon={<ArrowBackIos />}
      />
      :
      <NavigationItem
          link='/login'
          text='Login'
          icon={<ArrowForwardIos />}
      />;

  let links;

  if (currentUser) {
    if (isAdmin) {
      links = (
          <List>
            {nav}
            <Divider />
            <NavigationItem
                link='/orders'
                text='Orders'
                icon={<History />}
            />
            <NavigationItem
                link='/parts'
                text='Parts'
                icon={<WorkOutline />}
            />
            <NavigationItem
                link='/filters'
                text='Filters'
                icon={<FilterList />}
            />
            <NavigationItem
                link='/users'
                text='Users'
                icon={<Person />}
            />
            <NavigationItem
                link='/categories'
                text='Categories'
                icon={<Category />}
            />
            <Divider />
            <NavigationItem
                link='/shopping'
                text='Shopping'
                icon={<ShoppingCart />}
            />
            <NavigationItem
                link='/cartinventory'
                text='Cart Inventory'
                icon={<HomeRepairService />}
            />
            <NavigationItem
                link='/supportdocuments'
                text='Tech Support Documents'
                icon={<Description />}
            />
            <NavigationItem
                link='/maintenance'
                text='Recommended Preventative Maintenance'
                icon={<Build />}
            />
            <Divider />
            {/*<NavigationItem link='/qr' text='QR Manager' icon={<CropFree />} />*/}
            <NavigationItem
                link='/kits'
                text='Mass Picture Upload'
                icon={<BurstMode />}
            />
          </List>
      );
    } else {
      links = (
          <List>
            {nav}
            <Divider />
            <NavigationItem
                link='/shopping'
                text='Shopping'
                icon={<ShoppingCart />}
            />
            {isInstall && <NavigationItem
                link='/cartinventory'
                text='Cart Inventory'
                icon={<HomeRepairService />}
            />}
            <NavigationItem
                link='/orderhistory'
                text='Orders History'
                icon={<History />}
            />
            <Divider />
            <NavigationItem
                link='/supportdocuments'
                text='Tech Support Documents'
                icon={<Description />}
            />
            <NavigationItem
                link='/maintenance'
                text='Recommended Preventative Maintenance'
                icon={<Build />}
            />
          </List>
      );
    }
  } else {
    links = (
        <List>
          {nav}
          <Divider />
          <NavigationItem
              link='/'
              exact
              text='Home'
              icon={<Home />}
          />
        </List>
    );
  }

  return links;
};

export default NavigationItems;
