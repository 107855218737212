import React, { Fragment, useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    paginationItems: {
        '& .MuiPagination-ul': {
            justifyContent: 'center'
        }
    }
}));

const ControlledPagination = ({ items, numberPerPage, loading, setItems }) => {
    const styles = useStyles();
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(1);

    useEffect(() => {
        setNumberOfPages(Math.ceil(items.length / numberPerPage));
    }, [items]);

    useEffect(() => {
        if (items) {
            const start = ((currentPageIndex - 1) * numberPerPage);
            const end = (start + numberPerPage);
            const paginatedItems = items.slice(start, end);
            setItems(paginatedItems);
        }
    }, [currentPageIndex, items]);

    return (
        <Fragment>
            <Pagination
                className={styles.paginationItems}
                size={"small"}
                count={numberOfPages}
                page={currentPageIndex}
                disabled={loading}
                onChange={(e, value) => setCurrentPageIndex(value)}
            />
        </Fragment>
    );
}

export default ControlledPagination;
