import React, {useState} from 'react';
import {sendPasswordResetEmail} from 'firebase/auth';

import {Avatar, Button, Container, FormControl, Link, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {LockOutlined} from '@mui/icons-material';

import formStyles from '../../../components/UI/Styles/formStyle';
import {auth, passwordResetUrl} from '../../../firebase';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const classes = formStyles();

  const submitHandler = (event) => {
    event.preventDefault();

    const validEmail = /\S+@\S+\.\S+/;
    let isValid = true;
    if (!email.match(validEmail)) {
      setEmailError('Poorly formatted email');
      isValid = false;
    }

    if (isValid) {
      setEmailError('');
      const actionCodeSettings = {
        url: passwordResetUrl,
        handleCodeInApp: false,
      };

      sendPasswordResetEmail(auth, email, actionCodeSettings).
          then(() => setResult('Successfully sent reset email to: ' + email +
              '. If you dont see it within a minute, check your junk and/or spam folder.')).
          catch(error => setError('Error sending Email: ' + error.message));
    }
  };

  return (
      <Container
          component='main'
          maxWidth='sm'
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography
              component='h1'
              variant='h5'
          >
            Forgot Password
          </Typography>
          <br />
          <form onSubmit={submitHandler}>
            <Grid
                container
                spacing={2}
            >
              <Grid xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                      variant='outlined'
                      required
                      fullWidth
                      id='email'
                      label='Email Address'
                      {...(emailError && {error: true, helperText: emailError})}
                      autoFocus
                  />
                </FormControl>
              </Grid>
              {error &&
                  <Grid xs={12}>
                    <Typography color={'error'}>{error}</Typography>
                  </Grid>}
              {result && <Grid xs={12}>
                <Typography>
                  {result}
                </Typography>
              </Grid>}
              <Grid xs={12}>
                <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                >
                  Reset Password
                </Button>
              </Grid>
              <Grid
                  container
                  xs={12}
                  sx={{justifyContent: 'space-between'}}
              >
                <Grid>
                  <Link
                      href='/'
                      variant='body2'
                  >
                    Login
                  </Link>
                </Grid>
                <Grid>
                  <Link
                      href='/register'
                      variant='body2'
                  >
                    Don't have an account? Register
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
  );
};

export default Forgot;
