import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';

import {Container, Grid, Grow} from '@mui/material';

import ItemCard from './Cards/ItemCard';
import BackButton from '../../../../components/UI/Buttons/BackButton';

function importAll(r) {
  return r.keys().map(r);
}

const pdfs = importAll(require.context('../../../../assets/files/Backroom Cut Sheets', false, /\.(pdf)$/));

const SheetsView = ({cutSheets}) => {
  const [sheets, setSheets] = useState([]);
  let {category} = useParams();
  let delay = 0;

  useEffect(() => {
    const tempSheets = cutSheets.filter(item => item.category.trim() === category);
    setSheets(tempSheets);
  }, [cutSheets, category]);

  return (
      <Container>
        <Grid
            container
            spacing={2}
        >
          <Grid
              item
              xs={12}
          >
            <BackButton backPage={'/supportdocuments'} />
          </Grid>
          {sheets.map(item => {
            delay += 100;
            const pdfIndex = pdfs.findIndex(sheet => sheet.default.includes(item.file));
            const pdf = pdfs[pdfIndex].default;
            return (
                <Grow
                    in={true}
                    key={item.name}
                    style={{transformOrigin: '0 0 0'}} {...{timeout: delay}}>
                  <Grid
                      item
                      xs={6}
                      md={4}
                      lg={3}
                  >
                    <ItemCard
                        item={item}
                        pdf={pdf}
                    />
                  </Grid>
                </Grow>
            );
          })}
        </Grid>
      </Container>
  );
};

const mapStateToProps = state => {
  return {
    cutSheets: state.cutSheets.cutSheets,
  };
};

export default connect(mapStateToProps)(SheetsView);
