import React from 'react';
import {Link} from 'react-router-dom';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';

import {Card, CardActionArea, CardContent, Typography} from '@mui/material';

import alt_image from '../../../../../assets/images/alt_image.jpg';
import * as classes from './Card.module.css';

const ItemCard = ({item, pdf}) => {
  return (
      <Card
          key={item.name}
          className={classes.Card}
      >
        <CardActionArea
            className={classes.CardActionArea}
            component={Link}
            to={pdf}
            target={'_blank'}
            rel={'noopener noreferrer'}
        >
          <Document
              file={pdf}
              className={classes.Document}
              loading={
                <img
                    src={item.itemPicture ? item.itemPicture : alt_image}
                    alt={'img'}
                    style={{maxHeight: '200px'}}
                />
              }
          >
            <Page
                pageNumber={1}
                height={200}
                className={classes.Page}
            />
          </Document>
          <CardContent>
            <Typography
                gutterBottom
                variant='h6'
                style={{textAlign: 'center', wordBreak: 'break-word'}}
            >
              {item.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
  );
};

export default ItemCard;
