import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, } from "firebase/firestore";

import * as actionTypes from './actionTypes';
import { auth, firestore, functions } from "../../firebase";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = () => {
    return {
        type: actionTypes.AUTH_SUCCESS,
    };
};

export const registerSuccess = () => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        registered: true
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const getCurrentUser = (user) => {
    return {
        type: actionTypes.FETCH_CURRENT_USER,
        first: user.first,
        last: user.last,
        nickName: user.nickName,
        email: user.email,
        locationName: user.locationName,
        address: user.address,
        city: user.city,
        state: user.state,
        zip: user.zip,
        locationPhone: user.locationPhone,
        managerPhone: user.managerPhone,
        id: user.id,
        approved: user.approved
    }
};

export const logout = () => {
    signOut(auth)
        .then(() => console.log("user signed out"));
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const login = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                dispatch(authSuccess());
            })
            .catch(error => {
                console.table(error);
                if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
                    dispatch(authFail('The entered email and password do not match any records in our system.'));
                } else if (error.code === 'auth/user-disabled') {
                    dispatch(authFail('This account is currently disabled, contact the administrator for access.'));
                } else {
                    dispatch(authFail(error.message));
                }
            })
    }
};

export const register = (registerData) => {
    return dispatch => {
        dispatch(authStart());
        const createNewUser = httpsCallable(functions, 'createNewUser');
        createNewUser(registerData)
            .then(() => {
                dispatch(registerSuccess());
            })
            .catch(error => {
                dispatch(authFail(error.message))
            });
    }
};

export const getUser = () => {
    return dispatch => {
        const { email } = auth.currentUser;
        getDoc(doc(firestore, "users", email))
            .then((user) => {
                dispatch(getCurrentUser(user.data()));
            });
    }
};

export const saveRedirectUrl = (redirectUrl) => {
    return {
        type: actionTypes.SET_REDIRECT_URL,
        redirectUrl: redirectUrl
    }
}
