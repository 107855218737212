import React, {Fragment, useContext, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {withSnackbar} from 'notistack';

import {Button, Checkbox, FormControl, FormControlLabel, Link, Slide, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import formStyles from '../../../components/UI/Styles/formStyle';
import Spinner from '../../../components/UI/Spinner/Spinner';
import * as actions from '../../../store/actions/index';
import {AuthContext} from '../Auth';

const Login = (props) => {
  const {currentUser, isAdmin} = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const classes = formStyles();

  useEffect(() => {
    if (localStorage.getItem('rememberMe')) {
      setRememberMe(true);
      setEmail(localStorage.getItem('email'));
      setPassword(localStorage.getItem('password'));
    }
    if (props.registered) {
      props.enqueueSnackbar('Registered successfully, we\'ll be in touch soon!', {
        variant: 'success',
        autoHideDuration: 12000,
        TransitionComponent: Slide,
        anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
      });
    }
  }, []);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
    }
  }, [email, password]);

  const onChangeHandler = () => {
    if (!rememberMe) {
      localStorage.setItem('rememberMe', true);
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
    } else {
      localStorage.clear();
    }
    setRememberMe(!rememberMe);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    props.onLogin(email, password);
  };

  if (currentUser) {
    const redirectUrl = props.redirectUrl ? props.redirectUrl : (isAdmin ? '/orders' : '/shopping');
    return <Navigate to={redirectUrl} />;
  }

  if (props.loading) {
    return <Spinner />;
  }

  return (
      <Fragment>
        <form onSubmit={submitHandler}>
          <Grid
              container
              spacing={2}
          >
            <Grid xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    variant='outlined'
                    required
                    fullWidth
                    id='email'
                    label='Email Address'
                    autoFocus
                />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    variant='outlined'
                    required
                    fullWidth
                    label='Password'
                    type='password'
                    id='password'
                />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControlLabel
                  control={<Checkbox
                      value={rememberMe}
                      checked={rememberMe}
                      onChange={onChangeHandler}
                      color='primary'
                  />}
                  label='Remember me'
              />
            </Grid>
            {props.error &&
                <Grid xs={12}>
                  <Typography color={'error'}>
                    {props.error}
                  </Typography>
                </Grid>
            }
            <Grid xs={12}>
              <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
              >
                Login
              </Button>
            </Grid>
            <Grid
                container
                xs={12}
                sx={{justifyContent: 'space-between'}}
            >
              <Grid>
                <Link
                    href='/forgot'
                    variant='body2'
                >
                  <small>
                    Forgot password?
                  </small>
                </Link>
              </Grid>
              <Grid>
                <Link
                    href='/register'
                    variant='body2'
                >
                  <small>
                    Don't have an account? Register
                  </small>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    registered: state.auth.registered,
    redirectUrl: state.auth.redirectUrl,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (email, password) => dispatch(actions.login(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Login));
