import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { Forward } from "@mui/icons-material";

export const BackButton = ({ backPage }) => {
    const navigate = useNavigate();
    return (
        <Button
            onClick={() => navigate(backPage)}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Forward style={{ transform: 'rotate(180deg)' }} />}
        >
            Back
        </Button>
    );
};

export default BackButton;
