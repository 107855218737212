import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { serverTimestamp } from "firebase/firestore";

import { Button, Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { AccountCircle } from '@mui/icons-material'; //update

import Stepper from "../../../../../components/UI/Stepper/Stepper";
import formStyles from "../../../../../components/UI/Styles/formStyle";
import ManagerDialog from "../../../../../components/UI/Dialog/ManagerDialog";
import Item from "./Item";

const EditForm = ({ formData, parts, users, statusList, onEdit }) => {
    const styles = formStyles();
    const [statusStep, setStatusStep] = useState(formData.statusStep);
    const [note, setNote] = useState(formData.note ? formData.note : "");
    const [partialOrder, setPartialOrder] = useState(formData.partialOrder ? formData.partialOrder : false);
    const [items, setItems] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        setItems(extractItems(formData.items));
    }, [formData.items]);

    const extractItems = (items) => {
        let tempItems = [];
        items.forEach((item, index) => {
            const partIndex = parts.findIndex(part => part.id === item.itemId);
            const foundPart = parts[partIndex];
            let part;
            if (foundPart !== undefined) {
                part = { ...foundPart, amount: item.amount };
            } else {
                part = { id: index, name: 'part no longer available' };
            }
            tempItems.push(part);
        });
        return tempItems;
    };

    const submitFormHandler = (event) => {
        event.preventDefault();
        const timeStamp = serverTimestamp();
        let order = {
            updatedAt: timeStamp,
            note: note,
            partialOrder: partialOrder,
            statusStep,
            status: statusList[statusStep - 1]
        };
        onEdit(order, formData.id);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <form autoComplete="off" onSubmit={submitFormHandler}>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Stepper statusList={statusList} status={statusStep} setStatus={setStatusStep} />
                </Grid>
                <Grid xs={12}>
                    <TextField
                        autoFocus
                        value={note}
                        onChange={event => setNote(event.target.value)}
                        id="outlined-textarea"
                        label="Note"
                        multiline
                        variant="outlined"
                        fullWidth
                        minRows={3}
                        inputProps={{ className: styles.textarea }}
                    />
                </Grid>
                <Grid xs={9} style={{ display: 'flex', alignItems: 'start' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={partialOrder}
                                checked={partialOrder}
                                onChange={() => setPartialOrder((prevState) => !prevState)}
                                color="primary"
                            />
                        }
                        label="Partially Complete Order"
                    />
                </Grid>
                <Grid xs={3}>
                    <Tooltip title="Manager Info">
                        <IconButton color="primary" component="span" onClick={handleDialogOpen}>
                            <AccountCircle />
                        </IconButton>
                    </Tooltip>
                    <ManagerDialog users={users} formData={formData} open={dialogOpen}
                                   onClose={handleDialogClose} />
                </Grid>
                {formData.shippingMethod &&
                    <Grid xs={12} style={{ textAlign: "start" }}>
                        <Typography>Shipping method: {formData.shippingMethod}</Typography>
                    </Grid>
                }
                {formData.comment &&
                    <Grid xs={12} style={{ textAlign: "start" }}>
                        <Typography>Comments: {formData.comment}</Typography>
                    </Grid>
                }
                <Grid xs={12}>
                    <Grid container spacing={1} style={{
                        maxHeight: '200px',
                        overflow: 'auto',
                        border: '1px solid rgba(0, 0, 0, 0.18)',
                        borderRadius: '5px'
                    }}>
                        {items.map(item => <Grid xs={12} key={item.id}><Item item={item} /></Grid>)}
                    </Grid>
                </Grid>
                <Grid xs={10} style={{ margin: 'auto' }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Save Order
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = state => {
    return {
        parts: state.parts.parts,
        users: state.users.users,
    };
};

export default connect(mapStateToProps)(EditForm);
