import React, { Fragment } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { Add, ExpandMore } from "@mui/icons-material";
import { StylesProvider } from "@mui/styles";

import EnhancedTable from "../../../../../components/UI/Table/Table";
import "./CartDrawer.css";

const CartDrawer = ({ drawer, handleOrderRequiredAmount, handleQuantityChanged }) => {
    //TODO remove after testing is complete { id: 'partId', label: 'Id', noSort: true },
    const headCells = [
        { id: 'partName', label: 'Name', noSort: true },
        { id: 'requiredQuantity', label: 'Required', noSort: true },
        {
            id: 'quantityChanged',
            label: 'Quantity on Cart',
            onChange: handleQuantityChanged,
            noSort: true,
            value: 'quantityOnCart',
            component: true,
            inputProps: { min: 0, max: 100, step: 1 },
            max: 'requiredQuantity'
        },
        {
            id: 'orderRequiredAmount',
            label: 'Dont Need',
            click: handleOrderRequiredAmount,
            noSort: true,
            component: true
        },
        { id: 'quantityNeeded', label: 'Quantity Needed', noSort: true },
    ];

    return (
        <Fragment>
            <StylesProvider injectFirst>
                <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <Typography>{drawer.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <EnhancedTable
                            data={drawer.items}
                            headCells={headCells}
                            noPagination
                            maxHeight={2000}
                        />
                    </AccordionDetails>
                </Accordion>
            </StylesProvider>
        </Fragment>
    )
}

export default CartDrawer;
