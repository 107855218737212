import React from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import alt_image from "../../../../../../assets/images/alt_image.jpg";

const useStyles = makeStyles(() => ({
    actionArea: {
        display: 'flex !important',
        height: '100% !important'
    },
    content: {
        flex: '1 !important',
        height: '100% !important',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important'
    },
    cover: {
        width: '100px !important',
        height: '100% !important',
        minHeight: '100 !important'
    },
    card: {
        height: '100% !important',
        border: '1px solid lightgrey !important',
        borderRadius: '15px !important',
        borderBottom: '0 !important'
    }
}));

const CategoryCard = props => {
    const styles = useStyles();
    const navigate = useNavigate();
    const pic = props.pictureUrl ? props.pictureUrl : alt_image;

    return (
        <Card key={props.key} className={styles.card}>
            <CardActionArea onClick={() => navigate(props.to)} className={styles.actionArea}>
                <CardContent className={styles.content}>
                    <Typography gutterBottom variant="h6">{props.name}</Typography>
                </CardContent>
                <div style={{ backgroundColor: 'lightgrey', height: '100%' }}>
                    <CardMedia
                        className={styles.cover}
                        loading={"lazy"}
                        component="img"
                        alt={props.name}
                        image={pic} />
                </div>
            </CardActionArea>
        </Card>
    );
};

export default CategoryCard;
