import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';
import {collection, getDocs, orderBy, query, where} from 'firebase/firestore';

import {Accordion, AccordionDetails, AccordionSummary, Button, Container, Slide, Typography} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';

import {firestore} from '../../../../firebase';
import formStyles from '../../../../components/UI/Styles/formStyle';
import * as actions from '../../../../store/actions';
import ItemView from '../Shopping/Views/ItemView';
import Spinner from '../../../../components/UI/Spinner/Spinner';

const OrderHistory = (props) => {
  const styles = formStyles();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const {email} = props;

  useEffect(() => {
    if (props.parts.length > 0) {
      fetchOrders().then(response => {
        setOrders(response);
      });
    }
  }, [email, props.parts]);

  useEffect(() => {
    if (props.orderComplete) {
      props.enqueueSnackbar('Order Complete', {
        variant: 'success',
        autoHideDuration: 9000,
        TransitionComponent: Slide,
        anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
      });
      props.clearOrder();
    }
  }, [props.orderComplete]);

  const extractItems = (order) => {
    let items = [];
    order.items.forEach((item, index) => {
      const partIndex = props.parts.findIndex(part => part.id === item.itemId);
      const foundPart = props.parts[partIndex];
      let part;
      if (foundPart !== undefined) {
        part = {...foundPart, amount: item.amount};
      } else {
        part = {id: index, name: 'part no longer available'};
      }
      items.push(part);
    });
    return {...order, items: items};
  };

  const createDateString = (seconds) => {
    const currentDate = new Date(seconds * 1000);
    return currentDate.toLocaleDateString('fr-CA');
  };

  const fetchOrders = async () => {
    setLoading(true);
    const ordersRef = query(collection(firestore, 'orders'), where('email', '==', props.email),
        orderBy('createdAt', 'desc'));
    const ordersList = [];
    await getDocs(ordersRef).then((res) => {
      res.forEach(order => {
        ordersList.push({...extractItems(order.data()), id: order.id});
      });
    }).catch((error) => console.log(error));
    setLoading(false);
    return ordersList;
  };

  const handleOrderAgain = (order, event) => {
    event.stopPropagation();
    order.items.forEach(item => {
      if (item.amount) {
        props.onAddItem(item, item.amount);
      }
    });
    props.enqueueSnackbar('Items added to your cart successfully', {
      variant: 'success',
      autoHideDuration: 3000,
      TransitionComponent: Slide,
      anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
    });
  };

  const ordersView = (
      orders.map((order) =>
          <div
              key={order.id}
              style={{margin: '8px 0'}}
          >
            <Accordion style={{alignContent: 'center'}}>
              <AccordionSummary
                  expandIcon={<ExpandMore />}
              >
                <div
                    style={{
                      justifyContent: 'space-between',
                      width: '100%',
                      display: 'flex',
                    }}
                >
                  <Typography style={{alignSelf: 'center'}}>
                    Ordered on {createDateString(order.createdAt.seconds)}
                  </Typography>
                  <Button
                      onClick={(event) => {
                        handleOrderAgain(order, event);
                      }}
                  >
                    Order Again
                  </Button>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ItemView parts={order.items} />
              </AccordionDetails>
            </Accordion>
          </div>,
      )
  );

  const noOrdersView = (
      <Typography>No Orders</Typography>
  );

  return loading ? <Spinner /> : (
      <Container className={styles.container}>
        {orders.length < 1 ? noOrdersView : ordersView}
      </Container>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onAddItem: (item, amount) => dispatch(actions.onAddItem(item, amount)),
    clearOrder: () => dispatch(actions.clearOrder()),
  };
};

const mapStateToProps = state => {
  return {
    email: state.auth.email,
    orderComplete: state.cart.orderComplete,
    parts: state.parts.parts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(OrderHistory));
