import React, { Fragment } from "react";

import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import Spinner from "../../Spinner/Spinner";

const DeleteForm = ({ formData, title, loading, buttonText, onDelete }) => (
    <Fragment>
        <Grid container spacing={1}>
            <Grid xs={12}>
                {loading
                    ? <Spinner />
                    : <Typography>{title}</Typography>}
            </Grid>
            <Grid xs={10} style={{ margin: 'auto' }}>
                <Button
                    disabled={loading}
                    variant="contained"
                    color={"primary"}
                    onClick={() => {
                        onDelete(formData.id)
                    }}
                    fullWidth
                >
                    {buttonText}
                </Button>
            </Grid>
        </Grid>
    </Fragment>
);

export default DeleteForm;
