import React from "react";
import { connect } from "react-redux";

import AddToCartCard from "../../../../containers/Pages/Client/Shopping/Cards/AddToCartCard/AddToCartCard";
import * as actions from "../../../../store/actions";
import TransitionModal from "../../Modal/Modal";

const SearchModal = ({ open, close, item, onAddItem }) => (
    <TransitionModal
        open={open}
        handleClose={close}
        form={<AddToCartCard item={item && item} close={close} onAddItem={onAddItem} />}
        title={item && item.name}
        maxWidth={"sm"}
    />
);

const mapDispatchToProps = dispatch => {
    return {
        onAddItem: (item, amount) => dispatch(actions.onAddItem(item, amount)),
    }
};

export default connect(null, mapDispatchToProps)(SearchModal);
