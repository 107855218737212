import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { loadSheetsData } from "../../../../components/UI/Helper/Helper";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Cart from "./components/Cart";
import CustomTabs from "../../../../components/UI/Tabs/Tabs";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";

const CartInventory = ({ parts, onAddItem }) => {
    const navigate = useNavigate();
    const fittingsJsonData = [
        {
            "drawer": "Self Tappers & 3/8” Bolts",
            "partId": "",
            "partName": "",
            "requiredQuantity": ""
        },
        {
            "drawer": "",
            "partId": "Mgfx694jry8h82bRnf4g",
            "partName": "AIR CYLINDER: 10in  - Air Cylinder",
            "requiredQuantity": "50"
        },
        {
            "drawer": "",
            "partId": "MWeWQrv9jxg161X2QnCO",
            "partName": "AIR CYLINDER: 3in  - Air Cylinder",
            "requiredQuantity": "50"
        },
        {
            "drawer": "",
            "partId": "3DYgTdFtfwWXAuTsW9Jx",
            "partName": "AIR CYLINDER: 8in  - Air Cylinder",
            "requiredQuantity": "25"
        },
        {
            "drawer": "Push to Fit (PTF) 1/2\"",
            "partId": "",
            "partName": "",
            "requiredQuantity": ""
        },
        {
            "drawer": "",
            "partId": "Mgfx694jry8h82bRnf4g",
            "partName": "BEARING: 1 1/2in  - 4 Bolt Flange Bearing",
            "requiredQuantity": "100"
        },
        {
            "drawer": "",
            "partId": "rRxyaOLjnQiSuFfWJ7Zh",
            "partName": "BEARING: 1 1/2in  - IP69K 4 Bolt Flange Bearing",
            "requiredQuantity": "50"
        }
    ];
    const [fittingsOrder, setFittingsOrder] = useState([])
    const plumbingJsonData = [
        {
            "drawer": "Self Tappers & 3/8” Bolts",
            "partId": "",
            "partName": "",
            "requiredQuantity": ""
        },
        {
            "drawer": "",
            "partId": "Mgfx694jry8h82bRnf4g",
            "partName": "AIR CYLINDER: 10in  - Air Cylinder",
            "requiredQuantity": "50"
        },
        {
            "drawer": "",
            "partId": "rRxyaOLjnQiSuFfWJ7Zh",
            "partName": "AIR CYLINDER: 3in  - Air Cylinder",
            "requiredQuantity": "50"
        },
        {
            "drawer": "",
            "partId": "3DYgTdFtfwWXAuTsW9Jx",
            "partName": "AIR CYLINDER: 8in  - Air Cylinder",
            "requiredQuantity": "25"
        },
        {
            "drawer": "Push to Fit (PTF) 1/2\"",
            "partId": "",
            "partName": "",
            "requiredQuantity": ""
        },
        {
            "drawer": "",
            "partId": "Mgfx694jry8h82bRnf4g",
            "partName": "BEARING: 1 1/2in  - 4 Bolt Flange Bearing",
            "requiredQuantity": "100"
        },
        {
            "drawer": "",
            "partId": "rRxyaOLjnQiSuFfWJ7Zh",
            "partName": "BEARING: 1 1/2in  - IP69K 4 Bolt Flange Bearing",
            "requiredQuantity": "50"
        }
    ];
    const [plumbingOrder, setPlumbingOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const fittingsCartSheetId = 1633414037;
    const plumbingCartSheetId = 1494037511;
    const practiceSheetId = 82002290;

    useEffect(() => {
        console.log("loading data");
        try {
            setDrawers();
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }, []);

    const setDrawers = async () => {
        const fittingsJsonData = await loadSheet(fittingsCartSheetId);
        const plumbingJsonData = await loadSheet(plumbingCartSheetId);
        setPlumbingOrder(createDrawers(fittingsJsonData));
        setFittingsOrder(createDrawers(plumbingJsonData));
    };

    async function loadSheet(sheetId) {
        try {
            setLoading(true);
            const response = await loadSheetsData(sheetId)
            return JSON.parse(response);
        } catch (error) {
            console.log(error);
            setError(error);
            return [];
        } finally {
            setLoading(false);
        }
    }

    const createDrawers = (jsonData) => {
        let drawers = [];
        let drawer;
        let drawerCount = -1;
        jsonData.forEach(item => {
            if (item.drawer.trim() !== "") {
                //if row only contains a title, create a new drawer and push to drawers list
                drawer = { title: item.drawer, items: [] };
                drawers.push(drawer);
                drawerCount++;
            } else {
                //else it's an item, add to drawer list
                drawer.items.push({ ...item, quantityOnCart: 0, drawerCount, quantityNeeded: item.requiredQuantity });
            }
        });
        return drawers;
    };

    const handleOrder = () => {
        addItemsFromOrderToCart(fittingsOrder);
        addItemsFromOrderToCart(plumbingOrder);
        navigate('/checkout');
    };

    const addItemsFromOrderToCart = (order) => {
        order.forEach(drawer => {
            drawer.items.forEach(item => {
                if (item.quantityNeeded !== 0) {
                    const partToAdd = itemMatcher(item.partId);
                    if (partToAdd) {
                        onAddItem(partToAdd, parseInt(item.quantityNeeded));
                    } else {
                        console.log("cant find part with name:" + item.partName);
                    }
                }
            })
        })
    };

    const itemMatcher = (partToOrderId) => {
        const partToOrderIndex = parts.findIndex(part => part.id === partToOrderId);
        let partToOrder = null;
        if (partToOrderIndex >= 0) {
            partToOrder = parts[partToOrderIndex];
        }
        return partToOrder;
    };

    const statusList = [
        'fittings cart',
        'plumbing cart',
    ];

    const comp1 = (<Cart drawers={fittingsOrder} setDrawers={setFittingsOrder} />);
    const comp2 = (<Cart drawers={plumbingOrder} setDrawers={setPlumbingOrder} />);
    const componentList = [comp1, comp2];

    return (
        <Container maxWidth="lg">
            <Typography>Beta Version:</Typography>
            <Typography>Developer Notes:</Typography>
            <Typography>As of this update, orders must be completed all at once. If you leave the page or
                refresh, it will reload and you will lose progress.
            </Typography>
            <ol>
                <li>
                    <Typography>Open each dropdown and fill out the form for that drawer</Typography>
                </li>
                <li>
                    <Typography>Fill out both tabs: fittings and plumbing cart</Typography>
                </li>
                <li>
                    <Typography>When you are done, click the "Complete inventory and checkout" button to add the items needed to the shopping cart and move to the checkout screen</Typography>
                </li>
            </ol>
            <Typography>(make sure you are truly done before heading to checkout, as the cart inventory will reset after)</Typography>
            <br />
            {loading ?
                <Spinner />
                :
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <CustomTabs
                            tabLabelList={statusList}
                            componentsList={componentList}
                            handleTabChange={() => {}}
                        />
                    </Grid>
                    {error &&
                        <Grid>
                            {error}
                        </Grid>
                    }
                    <Grid xs={10} style={{ margin: 'auto' }}>
                        <Button tabIndex={-1} variant="contained" fullWidth color="success" onClick={handleOrder}>
                            Complete inventory and checkout
                        </Button>
                    </Grid>
                </Grid>
            }
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        parts: state.parts.parts,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddItem: (item, amount) => dispatch(actions.onAddItem(item, amount)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartInventory);
