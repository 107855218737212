import React, {Fragment} from 'react';
import {withSnackbar} from 'notistack';

import CustomAppBar from '../../../components/Navigation/AppBar/AppBar';
import Footer from '../../../components/UI/Footer/Footer';
import classes from './Layout.module.css';

const Layout = ({children}) => (
    <Fragment>
      <div className={classes.MinHeight}>
        <CustomAppBar />
        <main className={classes.MainContent}>
          {children}
        </main>
      </div>
      <Footer />
    </Fragment>
);

export default withSnackbar(Layout);
