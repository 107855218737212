import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Button, CardMedia, FormControl, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import alt_image from '../../../../../../assets/images/alt_image.jpg';
import * as actions from '../../../../../../store/actions';

const AddToCartCard = ({item, onAddItem, close}) => {
  const [amount, setAmount] = useState('1');
  const pictureUrl = (item && item.pictures.length > 0) ? item.pictures[0].pictureUrl : alt_image;

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddItem(item, parseInt(amount));
    close();
    //TODO add snackbar
  };

  return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid
            container
            spacing={1}
            sx={{maxHeight: '80vh !important'}}
        >
          <Grid xs={12}>
            <CardMedia
                style={{borderRadius: 5, minHeight: 230, maxHeight: 512}}
                component='img'
                alt={item && item.name}
                image={pictureUrl}
            />
          </Grid>
          <Grid xs={12}>
            <Typography
                variant={'body1'}
                style={{textAlign: 'left'}}
            >Description: {item && item.details}.</Typography>
          </Grid>
          <Grid xs={12}>
            <FormControl style={{width: '100%'}}>
              <TextField
                  type={'number'}
                  onChange={(e) => setAmount(e.target.value)}
                  value={amount}
                  variant={'outlined'}
                  label={'Quantity'}
                  onInput={(e) => {
                    e.target.value = Math.max(0, e.target.value.slice(0, 5));
                  }}
                  InputProps={{inputProps: {min: 1, max: 1000000}}}
              />
            </FormControl>
          </Grid>
          <Grid
              md={6}
              xs={12}
          >
            <Button
                onClick={() => close()}
                variant={'outlined'}
                color={'secondary'}
                style={{width: '100%'}}
            >
              Close
            </Button>
          </Grid>
          <Grid
              md={6}
              xs={12}
          >
            <Button
                type={'submit'}
                variant={'contained'}
                color={'primary'}
                style={{width: '100%'}}
            >
              Add to Cart
            </Button>
          </Grid>
        </Grid>
      </form>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onAddItem: (item, amount) => dispatch(actions.onAddItem(item, amount)),
  };
};

export default connect(null, mapDispatchToProps)(AddToCartCard);
