import React, {Fragment} from 'react';
import {connect} from 'react-redux';

import {Accordion, AccordionDetails, AccordionSummary, Container, Grid, Typography} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';

import * as classes from './Shopping.module.css';
import ItemView from './Views/ItemView';

const Shopping = ({categories, parts, address, state, city, zip, locationName}) => (
    <Fragment>
      <div className={classes.BackGroundImage}>
        <div className={classes.ShadowBox} />
        <div className={classes.CaptionBox}>
          <Typography className={classes.CaptionTitle}>WELCOME{locationName ? ' ' + locationName : ''}!</Typography>
          {address && <Typography className={classes.CaptionTagLine}>{address}, {city}, {state} {zip}</Typography>}
        </div>
      </div>
      <Container className={classes.MainWrapper}>
        <Typography className={classes.MainTitle}>QUICK ORDER FORM</Typography>
        <br />
        {categories.map((category, index) =>
            <div
                key={category.id}
                style={{margin: '8px 0'}}
            >
              <Accordion
                  style={{alignContent: 'center'}}
                  TransitionProps={{unmountOnExit: true}}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className={classes.CategoryTitle}>
                    {category.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <ItemView
                        index={index}
                        parts={parts.filter((part) => part.categoryId === category.id)}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>,
        )}
      </Container>
    </Fragment>
);

const mapStateToProps = state => {
  return {
    categories: state.categories.categories,
    parts: state.parts.parts,
    locationName: state.auth.locationName,
    address: state.auth.address,
    state: state.auth.state,
    city: state.auth.city,
    zip: state.auth.zip,
  };
};

export default connect(mapStateToProps)(Shopping);
